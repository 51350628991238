import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  extractDTime,
  extractDate,
} from "../ReusableComponents/UtilityFunctions";
import {
  ActivityBox,
  MainBox,
  CommentTextField,
  CommentMainBox,
  NoActivityCircleIcons,
  NoActivityTypography,
  TimestampTypography,
  InnerBoxThree,
  InnerBoxFour,
  CircleIcons,
  InnerBoxOne,
  InnerBoxTwo,
  TimeStampTypography,
  SendIcons,
} from "../../Styles/TaskManagement/DetailTaskCommentSectionStyle";

function DetailTaskCommentSection(props) {
  const {
    cardStyle,
    taskData,
    reversedHistory,
    linkStyle,
    toggleForm,
    commentError,
    setCommentError,
    SaveComment,
    viewer,
    comment,
    setComment,
    handleSideToggleMobile,
    typeOptions,
  } = props;
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isMobile = useMediaQuery("(max-width:600px)");

  const createMarkup = (html) => {
    return { __html: html };
  };

  const filteredHistory = reversedHistory?.filter(
    (item) => item?.field !== "statusValue" && item?.field !== "statusColor"
  );
  return (
    <Card sx={cardStyle} style={{ height: "80vh" }}>
      <CardContent>
        <Box>
          <ActivityBox>
            <Typography sx={{ fontWeight: "bold" }} variant="h5">
              Activity
            </Typography>
          </ActivityBox>
          {isMobile && (
            <IconButton
              edge="end"
              color="primary"
              onClick={handleSideToggleMobile}
              aria-label="close"
              sx={{ position: "absolute", right: 10, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <MainBox>
            <InnerBoxOne>
              {taskData?.history?.length !== 0 ? (
                <>
                  {filteredHistory?.map((item, index) => {
                    return (
                      <Box key={index} sx={{ marginRight: "10px" }}>
                        <TimeStampTypography
                          isdarktheme={isDarkTheme ? "true" : "false"}
                        >
                          {extractDate(reversedHistory[index].timestamp) !==
                            extractDate(
                              reversedHistory[index - 1]?.timestamp
                            ) && extractDate(item.timestamp)}
                        </TimeStampTypography>
                        <InnerBoxTwo key={index} width="100%">
                          <CircleIcons
                            isdarktheme={isDarkTheme ? "true" : "false"}
                          />
                          <InnerBoxThree>
                            <InnerBoxFour
                              isdarktheme={isDarkTheme ? "true" : "false"}
                            >
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "assignToEmp" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.updatedBy.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.updatedBy.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    assign task to{" "}
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.assign.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.assign.empId === viewer
                                        ? "You"
                                        : item.assign.name}
                                    </Link>
                                    .
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "Work" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.updatedBy.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.updatedBy.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    stopped timer and worked {item.newValue}{" "}
                                    hours.
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "Comment" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.updatedBy.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.updatedBy.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    commented"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "actualHours" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.updatedBy.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.updatedBy.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change Actual Hours from "{item.oldValue} "{" "}
                                    {" to "} " {item.newValue} ".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "title" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.updatedBy.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.updatedBy.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change title from "{item.oldValue} "{" "}
                                    {" to "} " {item.newValue} ".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "Planned Hours" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item.updatedBy.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item.updatedBy.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change Planned Hours from "{item.oldValue} "{" "}
                                    {" to "} " {item.newValue} ".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "description" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change description from "
                                    <span
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.oldValue
                                      )}
                                    />
                                    "{" to "}{" "}
                                    <span
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.newValue
                                      )}
                                    />
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "status" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task status from" {item.oldValue}"
                                    {" to "}"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "priority" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task priority from "{item.oldValue}"
                                    {" to "}"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "tags" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task tags from "
                                    {Array.isArray(item.oldValue)
                                      ? item.oldValue.join(", ")
                                      : item.oldValue}
                                    "{" to "}"
                                    {Array.isArray(item.newValue)
                                      ? item.newValue.join(", ")
                                      : item.newValue}
                                    ".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "taskType" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task type from "{item.oldValue}"
                                    {" to "}"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "type" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task type from "
                                    {typeOptions.find(
                                      (type) => type._id === item.oldValue
                                    )?.taskTypeName || item.oldValue}"
                                    {" to "}
                                    "{typeOptions.find(
                                      (type) => type._id === item.newValue
                                    )?.taskTypeName || item.newValue}
                                    ".
                                  </>
                                )}
                              </Typography>

                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "parentTaskId" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task type from "
                                    {taskData.parentTaskList.find(
                                      (parentTask) =>
                                        parentTask._id === item.oldValue
                                    )?.ticketNo || item.oldValue}"
                                    {" to "}
                                    "{taskData.parentTaskList.find(
                                      (parentTask) =>
                                        parentTask._id === item.newValue
                                    )?.ticketNo || item.newValue}
                                    ".
                                  </>
                                )}
                              </Typography>

                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "category" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task category from "{item.oldValue}"
                                    {" to "}"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "dueDate" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change dueDate from "{item.oldValue}"
                                    {" to "}"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "sprint" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    change task sprint from "{item.oldValue}"
                                    {" to "}"{item.newValue}".
                                  </>
                                )}
                              </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                                {item.field === "Start" && (
                                  <>
                                    <Link
                                      component="button"
                                      onClick={() =>
                                        toggleForm(item?.updatedBy?.empId)
                                      }
                                      style={linkStyle}
                                    >
                                      {item?.updatedBy?.empId === viewer
                                        ? "You"
                                        : item.updatedBy.name}
                                    </Link>{" "}
                                    started timer.
                                  </>
                                )}
                              </Typography>
                            </InnerBoxFour>
                            <Box sx={{ width: "16%" }}>
                              <TimestampTypography>
                                {extractDTime(item.timestamp)}
                              </TimestampTypography>
                            </Box>
                          </InnerBoxThree>
                        </InnerBoxTwo>{" "}
                      </Box>
                    );
                  })}
                </>
              ) : (
                <NoActivityTypography
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  <NoActivityCircleIcons
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                  No Activity.
                </NoActivityTypography>
              )}
            </InnerBoxOne>
          </MainBox>
          <CommentMainBox>
            <CommentTextField
              fullWidth
              isdarktheme={isDarkTheme ? "true" : "false"}
              name="comment"
              value={comment}
              multiline
              maxRows={2}
              label="Add Comment"
              onChange={(e) => {
                setComment(e.target.value);
                setCommentError("");
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={SaveComment}>
                    <SendIcons isdarktheme={isDarkTheme ? "true" : "false"} />
                  </IconButton>
                ),
                style: { overflow: "auto" },
              }}
            />
            <Typography color="error">{commentError}</Typography>
          </CommentMainBox>
        </Box>
      </CardContent>
    </Card>
  );
}

export default DetailTaskCommentSection;
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Tooltip, tooltipClasses } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  setEmpId,
  setEmployeeIndexShow,
} from "../../../Slices/employeeDetailSlice";
import { setCalenderValue } from "../../../Slices/searchData";
import { numberArray } from "../../Constant/constant";
import { getEmployeesName } from "../../../Services/EmployeeManageServices";

const EmpDataGrid = ({ selectedButton, team, Empdata }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const empData = useSelector((state) => state.employeeData.value);
  const user = useSelector((state) => state.selectedActiveView.role);
  const adminLogin = user === "admin";
  // const employee = useSelector((state) => state?.employeeData?.value);
  const employeeData =
    selectedButton === "DeActive Employees"
      ? empData.filter((data) => data?.status === "Deactive")
      : empData.filter((data) => data?.status === "Active");

  const [allData, setAllData] = useState([]);
  const [dataGridHeight, setDataGridHeight] = useState(false);
  // function stringAvatar(name) {
  //   return {
  //     children: `${name.split(" ")[0][0]}${name.split(" ")[1] ? name.split(" ")[1][0] : ""
  //       }`,
  //   };
  // }

  function stringAvatar(name) {
    if (!name || typeof name !== "string") {
      return { children: "?" };
    }
    const nameParts = name.split(" ");
    return {
      children: `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ""}`,
    };
  }

  function getReportingNameArray(empId) {
    let empState = allData
      ?.filter((employee) => empId.includes(employee.empId))
      .map((state) => state.firstName);
    return empState;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const changeToParticularEmployee = (empId) => {
    navigate(`/employee-management/${empId}`);
    const date = formatDate(new Date());
    dispatch(setEmployeeIndexShow(empId));
    dispatch(setEmpId(empId));
    dispatch(setCalenderValue(date));
  };

  const FormattedEmployeeDetails = (team ? Empdata : employeeData)?.map((empdata) => ({
    id: empdata?.empId,
    name: empdata?.FirstName,
    working: empdata?.working,
    reportingperson: getReportingNameArray(empdata?.ReportingPerson),
    dateofjoin: formatDate(empdata?.Date_Of_Join),
    designation: empdata?.Designation,
    specializations: empdata?.specializations?.map(spec => spec.name).join(', ') || 'N/A',
    contactno: `+91 ${empdata?.Contact_Number}`,
  }));

  const fetchEmployeeName = async () => {
    try {
      const response = await getEmployeesName();
      if (response.status === 200) {
        setAllData(response.data.namesData);
      } else {
        setAllData([]);
      }
    } catch (error) {
    }
  };

  const adminColumns = useMemo(
    () => [
      { field: "id", headerName: "Emp ID", flex: 1.5 },
      { field: "name", headerName: "Name", flex: 1.7 },
      {
        field: "reportingperson",
        headerName: "Reporting Person",
        flex: 1.7,
        renderCell: (params) => (
          <Box sx={{ display: "flex" }}>
            {Array.isArray(params.value) &&
              params.value.map((name, index) => (
                <Tooltip
                  key={index}
                  title={name}
                  arrow
                  placement="bottom"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                        {
                          marginTop: "0px",
                        },
                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                        {
                          marginBottom: "0px",
                        },
                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "0px",
                        },
                        [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                        {
                          marginRight: "0px",
                        },
                      },
                    },
                  }}
                >
                  <Avatar
                    key={index}
                    sx={{
                      background: "var(--textProfile-background-color)",
                      color: "var(--primary-text-color)",
                      width: 32,
                      height: 32,
                      marginLeft: "10px",
                      fontSize: "90%",
                      border: "1px solid var(--primary-text-color)",
                    }}
                    {...stringAvatar(name)}
                  />
                </Tooltip>
              ))}
          </Box>
        ),
      },
      { field: "dateofjoin", headerName: "Date of Join", flex: 1.5 },
      { field: "designation", headerName: "Designation", flex: 2 },
      { field: "contactno", headerName: "Contact No.", flex: 1.5 },
    ],
    []
  )

  const employeeColumns = useMemo(
    () => [
      { field: "id", headerName: "Emp ID", flex: 1.5 },
      {
        field: "name", headerName: "Name", flex: 1.7,
        renderCell: (params) => {
          const isActive = params.row.working === "true";
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title={params.value}
                arrow
                placement="bottom"
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                        marginTop: '0px',
                      },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                        marginBottom: '0px',
                      },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
                        marginLeft: '0px',
                      },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
                        marginRight: '0px',
                      },
                    },
                  },
                }}
              >
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    sx={{
                      background: 'var(--textProfile-background-color)',
                      color: 'var(--primary-text-color)',
                      width: 32,
                      height: 32,
                      marginLeft: '10px',
                      fontSize: '90%',
                      border: '1px solid var(--primary-text-color)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    {...stringAvatar(params.value)}
                  >
                  </Avatar>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      backgroundColor: isActive ? '#3d933d' : '#c71b1b',
                      zIndex: 1,
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          );
        },
      },
      { field: "designation", headerName: "Designation", flex: 2 },
      { field: "specializations", headerName: "Specializations", flex: 2 },
      { field: "contactno", headerName: "Contact No.", flex: 1.5 },
    ],
    []
  )

  const employeeDetailsColumns = adminLogin ? adminColumns : employeeColumns

  useEffect(() => {
    if (adminLogin)
      fetchEmployeeName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box
        sx={{
          "@media (max-width: 600px)": {
            width: "250%",
          },
          maxHeight: "fit-content",
        }}
      >
        <DataGrid
          disableColumnMenu
          rowHeight={43}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          aria-selected="false"
          rows={FormattedEmployeeDetails}
          columns={employeeDetailsColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize:
                  parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                  10,
              },
            },
          }}
          onRowCountChange={(params) => {
            if (params === 0) {
              setDataGridHeight(true);
            } else {
              setDataGridHeight(false);
            }
          }}
          onRowClick={(params) => {
            if (team) {
              return;
            }
            changeToParticularEmployee(params.id);
          }}
          onPaginationModelChange={(pageSize) => {
            window.localStorage.setItem("paginationNo", pageSize.pageSize);
          }}
          onPageSizeChange={(pageSize) => {
            window.localStorage.setItem("paginationNo", pageSize);
          }}
          pageSizeOptions={numberArray}
          components={{
            Toolbar: GridToolbar,
            // noRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            height: dataGridHeight ? "230px" : "fit-content",
            ".MuiDataGrid-filler": {
              height: "0px !important",
              display: "none",
            },
            color: "var(--primary-text-color)",
            textTransform: "capitalize",
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "none !important",
            },
            backgroundColor: "var(--subAccordian-background-color)",
            ".MuiDataGrid-cell ": {
              cursor: "pointer",
              alignContent: "center",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            ".MuiDataGrid-columnHeader": {
              height: "48px !important",
            },
            ".MuiInput-underline:before": {
              borderBottom: "1px solid var(--primary-text-color) !important",
            },
            ".MuiSvgIcon-fontSizeSmall": {
              fill: "var(--primary-text-color) !important",
            },
            ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
            {
              color: "var(--primary-text-color) !important",
            },
            ".MuiDataGrid-overlay": {
              color: "var(--primary-text-color) !important",
              backgroundColor: "var(--subAccordian-background-color)",
            },
            // ".MuiInput-underline:before": {
            //   borderBottom: "1px solid var(--primary-text-color) !important",
            // },
            ".MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            // ".MuiSelect-icon": {
            //   fill: "var(--secondary-text-color)",
            // },
            // ".MuiTablePagination-root": {
            //   color: "var(--secondary-text-color)",
            // },
            // ".MuiDataGrid-root": {
            //   color: "var(--secondary-text-color)",
            // },
            // ".MuiDataGrid-cellContent": {
            //   color: "var(--secondary-text-color)",
            //   fontSize: "14px",
            // },
            // ".MuiDataGrid-columnHeader": {
            //   color: "var(--secondary-text-color)",
            //   fontSize: "18px",
            //   backgroundColor: "var(--primary-highlight-color)",
            //   width: "100vh",
            // },
            // ".MuiDataGrid-footerContainer": {
            //   color: "var(--secondary-text-color)",
            // },
            // ".MuiDataGrid-menuIconButton": {
            //   color: "var(--secondary-text-color)",
            // },
            // ".MuiDataGrid-sortIcon": {
            //   color: "var(--secondary-text-color)",
            //   visibility: true,
            // },
            // "MuiDataGrid-virtualScroller": { color: "red" },
            ".MuiDataGrid-actionsCell": { type: "actions" },
            // ".MuiDataGrid-columnHeader:focus": {
            //   outlineColor: "rgb(25, 118, 210)",
            //   outlineStyle: "solid",
            //   outlineWidth: "0px",
            // },

            //color: "var(--secondary-text-color)",
            // // overflow: overlay
            // ".MuiDataGrid-cell:focus": {
            //   outlineColor: "rgb(25, 118, 210)",
            //   outlineStyle: "solid",
            //   outlineWidth: "0px",
            // },
          }}
        />
      </Box>
    </Box>
  );
};

export default EmpDataGrid;

import { Box, Button, DialogActions, IconButton, Table, TableCell, TableContainer, TableHead, Typography, styled } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import DoneIcon from "@mui/icons-material/Done";

export const MainBox = styled(Box)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "#121e2e" : "#ffffff",
    padding: "8px"
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "8px 5px" : "0px",
}))

export const InnerBoxOneDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))


export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
    borderRadius: "1000px",
    padding: "8px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // width: "590px",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1000px",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxThree = styled(Box)(({ isdarktheme, ismobile }) => ({
    display: "flex",
    padding: "0px",
    justifyContent: ismobile !== "true" && "flex-start",
    alignItems: "center",
    marginRight: ismobile !== "true" && "5px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    paddingLeft: "38px",
    marginTop: "0px",
}))

export const DialogsActions = styled(DialogActions)(({ displayError }) => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
    display: "flex",
    justifyContent: displayError && "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
        flexDirection: displayError && "column",
        justifyContent: displayError && "flex-end",
    },
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    borderRadius: "5px",
}))

export const CustomButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const TableMainBox = styled(Box)(() => ({
    padding: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
}))

export const TableContainers = styled(TableContainer)(() => ({
    borderRadius: "5px !important",
}))

export const Tables = styled(Table)(({ isdarktheme }) => ({
    "--table-border-color": isdarktheme === "true"
        ? "#5e6e82"
        : "#e0e0e0cc",
    '@media (max-width: 600px)': {
        width: '145%',
    },
}))

export const TableHeads = styled(TableHead)(() => ({
    background: "var(--primary-highlight-color)",
    height: "fit-content",
}))

export const TableCells = styled(TableCell)(({ emptasktableheadcell, isdarktheme }) => ({
    ...emptasktableheadcell,
    color: isdarktheme === "true"
        ? "#e0e0e0e3 !important"
        : "#544f5a !important",
    fontSize: "12px !important",
    paddingTop: "3px !important",
    paddingBottom: "3px !important ",
}))

export const CustomTableCells = styled(TableCell)(({ emptasktablebodycell, isdarktheme }) => ({
    ...emptasktablebodycell,
    fontWeight: "500 !important",
    background: isdarktheme === "true"
        ? "#142840"
        : "#ffffff",
    color: isdarktheme === "true"
        ? "#e0e0e0e3 !important"
        : "#544f5a !important",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "31%",
}))

export const DoneIcons = styled(DoneIcon)(() => ({
    paddingLeft: "3px",
    color: "var(--start-color)",
    marginTop: "0px",
    position: "relative",
}))

export const ClearIcons = styled(ClearIcon)(() => ({
    paddingRight: "3px",
    color: "var(--late-owl-point-color)",
    marginTop: "0px",
    position: "relative",
}))



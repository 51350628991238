import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowRight from "@mui/icons-material/ArrowRight";

import { Dropdown, DropdownMenuItem } from '../../NastedDropdown/Dropdown';
// import { formatReverseDate } from '../../ReusableComponents/UtilityFunctions';
import TableComponent from '../../ReusableComponents/TableComponent';
import Loader from '../../Loader/Loader';
import { getLeaveBalanace, getLeavesDropdown } from '../../../Services/LeaveManageServices';
import { DropdownNestedMenuItems, MainBox, TitleBox, LoadingBox } from '../../../Styles/UpdateEmployee/ShowEmployeeBalanceStyle'

function ShowEmployeeBalance(props) {
    const { employeeName, handleBackLeaveBalance, employeeEmpId } = props;
    const isMobile = useMediaQuery("(max-width:600px)");

    const [leavesDropdownArray, setLeavesDropdownArray] = useState([]);
    const [employeeLeaveBalance, setEmployeeLeaveBalance] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedData, setSelectedData] = useState('');

    const handleChange = (data, month) => {
        if (selectedData !== month) {
            setSelectedData(month);
            getleaveBalanaceData(data);
        }
    };

    const getleaveBalanaceData = async (data) => {
        // if (data) {
        try {
            setIsLoading(true);
            const response = await getLeaveBalanace(data ?? "", employeeEmpId);
            if (response.status === 200 || response.status === 201) {
                const formattedLeaveData = response?.data?.viewLeavesArray?.reverse();
                setIsLoading(false);
                setEmployeeLeaveBalance(formattedLeaveData);
            }
            else {
                setEmployeeLeaveBalance([])
            }
        } catch (error) {
            setIsLoading(false);
        }
        // }
    };

    useEffect(() => {
        const fetchLeavesDropdown = async () => {
            try {
                const response = await getLeavesDropdown()
                if (response.status === 200 || response.status === 201) {
                    setLeavesDropdownArray([{ key: "All", value: [] }, ...response.data.leavesDropdownArray]);
                }
                else {
                    setLeavesDropdownArray([])
                }
            } catch (error) {
            }
        };
        fetchLeavesDropdown();
    }, []);

    useEffect(() => {
        if (selectedData === '') {
            // const date = formatReverseDate(new Date());
            getleaveBalanaceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData]);
    return (
        <Box>
            <MainBox>
                <TitleBox>
                    {/* Box for items on the left */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton sx={{ paddingLeft: isMobile && "0px" }} onClick={handleBackLeaveBalance}>
                            <ArrowBackIcon color="primary" />
                        </IconButton>
                        <Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ fontWeight: "bold" }}>
                            Leave Balance ({" "}
                            {employeeEmpId}: {employeeName}{" "}
                            )
                        </Typography>
                    </Box>
                    {/* Box for items on the right */}
                </TitleBox>
                <Box sx={{ marginTop: "20px" }}>
                    <Box sx={{ overflow: "auto" }}>
                        <Box sx={{ marginBottom: '15px' }}>
                            <Dropdown
                                trigger={<Button sx={{ padding: isMobile ? "3px 8px" : "6px 16px" }} variant='contained'>{selectedData ? selectedData : "select Year"}</Button>}
                                menu={leavesDropdownArray.map((data, index) => (
                                    <DropdownNestedMenuItems
                                        keepopen={"false"}
                                        key={index}
                                        label={data.key}
                                        onClick={() => {
                                            if (data?.key === "All") {
                                                setSelectedData('');
                                            } else {
                                            }
                                        }}
                                        rightIcon={data.value.length > 0 ? <ArrowRight sx={{ marginLeft: '4px !important' }} /> : null}
                                        menu={data.value.length > 0 ?
                                            data.value.map((subData, subIndex) => (
                                                <DropdownMenuItem
                                                    keepopen={"false"}
                                                    // style={{ padding: '4px 10px 4px 10px'}}
                                                    onClick={() => handleChange(subData.EndDate, subData.month)}
                                                >
                                                    {subData.month}
                                                </DropdownMenuItem>
                                            ))
                                            : null
                                        }
                                    />
                                ))
                                }
                            />
                        </Box>
                    </Box>
                </Box>
                {isLoading ?
                    <LoadingBox>
                        <Loader />
                    </LoadingBox>
                    :
                    <Box>
                        {employeeLeaveBalance?.length > 0 && <TableComponent array={employeeLeaveBalance} />}
                    </Box>
                }
            </MainBox>
        </Box>
    )
}

export default ShowEmployeeBalance
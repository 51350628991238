import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  useMediaQuery,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Add, Edit, Delete, Close } from "@mui/icons-material";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import {
  fetchCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from "../../Services/TaskManageServices";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import {
  TitleTypography,
  TextTypography,
  CancelButton,
  DeleteButton,
  SaveButton,
  InnerBoxOneDialog,
  InnerBoxTwoDialog,
  CloseIconButton,
  MainBoxDialog,
  MainBoxAddDialog,
  InputTextField
} from "../../Styles/TaskManagement/TaskCategoryStyle";
import { ErrorTypography } from "../../Styles/TaskManagement/TaskTypeStyle";

const TaskCategory = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newSprintPoint, setNewSprintPoint] = useState(1);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editCategory, setEditCategory] = useState({
    name: "",
    sprintPoint: 1,
  });
  const [error, setError] = useState({});

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleEditCategory = (index) => {
    setEditIndex(index);
    setEditCategory(categories[index]);
    setEditDialogOpen(true);
  };

  const handleAddCategory = () => {
    const newError = {};
    if (!newCategory) {
      newError.name = "Category is required";
    }
    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }
    if (newCategory.trim()) {
      const newCategoryData = {
        name: newCategory,
        sprintPoint: newSprintPoint,
      };
      addCategory(newCategoryData)
        .then((response) => {
          setCategories([...categories, response.data]);
          setError({})
          setAddDialogOpen(false);
          setNewCategory("");
          setNewSprintPoint(1);
          dispatch(setToast(true));
          dispatch(setToastMsg("Category added successfully"));
          dispatch(setToastType("success"));
        })
        .catch((error) => console.error("Error adding category:", error));
    }
  };

  const handleSaveEdit = () => {
    const { name, sprintPoint } = editCategory;
    const newError = {};
    if (!name) {
      newError.name = "Category is required";
    }
    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }
    updateCategory(categories[editIndex]._id, { name, sprintPoint })
      .then(() => {
        const updatedCategories = [...categories];
        updatedCategories[editIndex] = editCategory;
        setCategories(updatedCategories);
        setError({})
        setEditDialogOpen(false);
        dispatch(setToast(true));
        dispatch(setToastMsg("Category updated successfully"));
        dispatch(setToastType("success"));
      })
      .catch((error) => console.error("Error updating category:", error));
  };

  const handleConfirmDelete = () => {
    const categoryId = categories[deleteIndex]._id;
    deleteCategory(categoryId)
      .then(() => {
        const updatedCategories = categories.filter(
          (_, i) => i !== deleteIndex
        );
        setCategories(updatedCategories);
        setDeleteDialogOpen(false);
        dispatch(setToast(true));
        dispatch(setToastMsg("Category deleted successfully"));
        dispatch(setToastType("success"));
      })
      .catch((error) => console.error("Error deleting category:", error));
  };

  const renderDeleteDialogBox = () => {
    const categoryName = categories[deleteIndex]?.name || "this";
    return (
      <Box
        sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "8px" }}
      >
        <MainBoxDialog>
          <Box>
            <DialogTitle
              sx={{
                fontWeight: "bold",
                padding: "0px 0px",
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              <InnerBoxOneDialog>
                <Box sx={{ padding: "8px 5px" }}>
                  <InnerBoxTwoDialog
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                      color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                    }}
                  >
                    Confirm Delete
                  </Box>
                </Box>
              </InnerBoxOneDialog>
            </DialogTitle>
          </Box>
          <Box>
            <CloseIconButton
              onClick={() => setDeleteDialogOpen(false)}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </MainBoxDialog>
        <DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
          <TextTypography sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
            Are you sure you want to delete{" "}
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              {categoryName}
            </span>{" "}
            category?
          </TextTypography>
        </DialogContent>
        <DialogActions>
          <CancelButton
            onClick={() => setDeleteDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </CancelButton>
          <DeleteButton
            variant="contained"
            onClick={handleConfirmDelete}
            color="error"
          >
            Delete
          </DeleteButton>
        </DialogActions>
      </Box>
    );
  };

  const renderCategoryDialogBox = (isEditMode) => {
    const dialogTitle = isEditMode ? "Edit Task Category" : "Add Task Category";
    const handleSave = isEditMode ? handleSaveEdit : handleAddCategory;
    const categoryData = isEditMode
      ? editCategory
      : { name: newCategory, sprintPoint: newSprintPoint };

    return (
      <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "15px" }}>
        <MainBoxAddDialog>
          <Box>
            <DialogTitle sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a", padding: "0px 0px", }}>
              {dialogTitle}
            </DialogTitle>
          </Box>
          <Box>
            <CloseIconButton
              ismobile={isMobile ? "true" : "false"}
              onClick={() => { isEditMode ? setEditDialogOpen(false) : setAddDialogOpen(false); setError({}) }
              }
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </MainBoxAddDialog>
        <DialogContent sx={{ padding: "0px" }}>
          <InputTextField
            label={isEditMode ? "Edit Category Name" : "Add Category Name"}
            isdarktheme={isDarkTheme ? "true" : "false"}
            value={categoryData.name}
            onChange={(e) => {
              isEditMode
                ? setEditCategory({ ...editCategory, name: e.target.value })
                : setNewCategory(e.target.value);
              if (error["name"] && e.target.value !== "") {
                setError((prevError) => {
                  const newError = { ...prevError };
                  delete newError["name"];
                  return newError;
                });
              }
            }
            }
            fullWidth
            margin="normal"
          />
          <ErrorTypography>{error.name}</ErrorTypography>
          <InputTextField
            label={isEditMode ? "Edit Sprint Points" : "Sprint Points"}
            type="number"
            isdarktheme={isDarkTheme ? "true" : "false"}
            value={categoryData.sprintPoint}
            onChange={(e) =>
              isEditMode
                ? setEditCategory({
                  ...editCategory,
                  sprintPoint: Number(e.target.value),
                })
                : setNewSprintPoint(Number(e.target.value))
            }
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions sx={{ padding: "8px 0px 0px 8px" }}>
          <CancelButton
            onClick={() => { isEditMode ? setEditDialogOpen(false) : setAddDialogOpen(false); setError({}) }
            }
          >
            Cancel
          </CancelButton>
          <SaveButton variant="contained" onClick={handleSave}>
            {isEditMode ? "Update" : "Add"}
          </SaveButton>
        </DialogActions>
      </Box>
    );
  };

  useEffect(() => {
    fetchCategories()
      .then((response) => setCategories(response.data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  return (
    <Box>
      {isMobile ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <TitleTypography variant="h6" gutterBottom>
              Manage Task Categories
            </TitleTypography>
            <TextTypography gutterBottom>
              Each category has default Sprint Points, which can be edited.
            </TextTypography>
          </Box>

          <Box style={{ alignSelf: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddDialog}
              startIcon={<Add />}
            >
              Add Category
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TitleTypography variant="h6" gutterBottom>
              Manage Task Categories
            </TitleTypography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddDialog}
              startIcon={<Add />}
            >
              Add Category
            </Button>
          </Box>

          <TextTypography gutterBottom>
            Each category has default Sprint Points, which can be edited.
          </TextTypography>
        </>
      )}

      <List>
        {categories.map((category, index) => (
          <ListItem
            key={category._id}
            divider
            sx={{
              borderBottom: "1px solid var(--table-border-color)",
            }}
          >
            <ListItemText
              primary={category.name}
              secondary={`${category.sprintPoint} Sprint Point(s)`}
              sx={{ color: "var(--secondary-text-color)" }}
              secondaryTypographyProps={{
                style: { color: "var(--secondary-text-color)" },
              }}
            />
            <ListItemSecondaryAction>
              <Tooltip
                title="Edit"
                placement="bottom"
                arrow
                TransitionComponent={Zoom}
              >
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={() => handleEditCategory(index)}
                  sx={{ color: "var( --secondary-text-color)" }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Delete"
                placement="bottom"
                arrow
                TransitionComponent={Zoom}
              >
                <IconButton
                  edge="end"
                  color="secondary"
                  onClick={() => handleOpenDeleteDialog(index)}
                  sx={{
                    color: isDarkTheme ? "#d94a38" : "#c62828",
                    marginLeft: 2,
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <DialogComponent
        open={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        maxWidth="280px"
        PaperProps={{
          sx: { width: "380px" }
        }}
        renderChildern={() => renderCategoryDialogBox(false)}
      />

      <DialogComponent
        open={isEditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="280px"
        PaperProps={{
          sx: { width: "380px" }
        }}
        renderChildern={() => renderCategoryDialogBox(true)}
      />

      <DialogComponent
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        renderChildern={renderDeleteDialogBox}
      />
    </Box>
  );
};

export default TaskCategory;

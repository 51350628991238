import { Typography, Button, styled, IconButton, DialogActions } from "@mui/material";
import { Box } from "@mui/system";

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
}));

export const TextTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
}));

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    borderRadius: "5px",
}))

export const DeleteButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const SaveButton = styled(Button)(() => ({
    // fontWeight: "bold",
    textTransform: "capitalize",
    // fontSize: "18px",
}));

export const AddButton = styled(Button)(() => ({
    textTransform: "capitalize",
}));

export const InnerBoxOneDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "8px 0px" : "0px",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    paddingLeft: "20",
    marginTop: "0px",
}))

export const DialogsActions = styled(DialogActions)(({ displayError }) => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
    display: "flex",
    justifyContent: displayError && "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
        flexDirection: displayError && "column",
        justifyContent: displayError && "flex-end",
    },
}))
export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
    borderRadius: "1000px",
    padding: "8px",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#62626280",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "10px",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
}))

export const MainBoxAddDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      maxWidth: "100%",
    },
  }));
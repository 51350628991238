import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { numberArray } from "../../Constant/constant";
import Loader from "../../Loader/Loader";
import {
  TaskTypeCell,
  formatDate,
  getIcon,
  sortingTask,
} from "../../ReusableComponents/UtilityFunctions";
import TaskDialogBox from "../../TaskManagement/DetailTask";
import { getEmployeeFetchTask } from "../../../Services/TaskManageServices";
import {
  InnerBoxOne,
  TaskEfficiencyButton,
} from "../../../Styles/TeamBoard/TeamBoardStyle";
import EmployeeEfficiency from "../../TaskManagement/EmployeeEfficiency/EmployeeEfficiency";

function SelectedEmployeeTaskDataGrid({ EmpId }) {
  const { employeeId } = useParams();
  const admin = useSelector((state) => state.adminData.adminData?.empId);
  const getTaskTypeList = useSelector(
    (state) => state?.selectedActiveView?.getTaskTypeList
  );
  const EmployeeId = employeeId || EmpId;

  const [employeeName, setEmployeeName] = useState("");
  // const [rowsPerPage, setRowsPerPage] = useState(null);
  const [taskData, setTaskData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataGridHeight, setDataGridHeight] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [taskEfficiencyShow, setTaskEfficiencyShow] = useState(false);

  function handleDetailClick(id) {
    setOpenDialog(true);
    setTaskId(id);
  }

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return "var(--pending-color)";
      case "Cancelled":
        return "var(--reject-color)";
      case "Completed":
        return "var(--approved-color)";
      case "Hold":
        return "var(--cancel-color)";
      case "To-Do":
        return "var(--primary-color)";
      default:
        return "var(--primary-color)";
    }
  };

  // function sortData(arr) {
  //   const sorted = arr.slice(0);
  //   sorted.sort((a, b) => {
  //     if (a.startDate === null) return 1;
  //     if (b.startDate === null) return -1;
  //     return 0;
  //   });
  //   return sorted;
  // }

  const removeHtmlTags = (str) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const taskDataRows = sortingTask(taskData).map((data, index) => ({
    fullrecord: data,
    id: index,
    empName: data.name,
    ticketNo: data.ticketNo,
    description: removeHtmlTags(data.description),
    startDate: formatDate(new Date(data.date)),
    plannedHour: data.plannedHours,
    timeSpent: data.actualHours,
    title: data.title,
    dueDate: formatDate(new Date(data.dueDate)),
    status: data.status,
    statusValue: data.statusValue,
    statusColor: data.statusColor,
    priority: data.priority,
    type: data?.type,
  }));

  const memoizedEmployeeFetchData = useMemo(() => {
    const getEmployeeData = async () => {
      setIsLoading(true);
      try {
        const response = await getEmployeeFetchTask(EmployeeId);
        if (response.status === 200) {
          setIsLoading(false);
          setTaskData(response.data.taskList);
          setEmployeeName(
            response.data.taskList.filter(
              (data) => data.empId === EmployeeId && data.name !== undefined
            )[0].name
          );
        } else {
          setIsLoading(false);
          setTaskData([]);
          setEmployeeName("");
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return getEmployeeData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EmployeeId]);

  const taskColumns = useMemo(
    () => [
      // { field: "empName", headerName: "Name", flex: 1.4 },
      // {
      // 	field: "empName",
      // 	headerName: "",
      // 	flex: 0.4,
      // 	renderCell: (params) => (
      // 		<Tooltip
      // 			title={params.value}
      // 			arrow
      // 			placement="bottom"
      // 			slotProps={{
      // 				popper: {
      // 					sx: {
      // 						[`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
      // 							{
      // 								marginTop: "0px",
      // 							},
      // 						[`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
      // 							{
      // 								marginBottom: "0px",
      // 							},
      // 						[`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
      // 							{
      // 								marginLeft: "0px",
      // 							},
      // 						[`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
      // 							{
      // 								marginRight: "0px",
      // 							},
      // 					},
      // 				},
      // 			}}
      // 		>
      // 			<Avatar
      // 				sx={{
      // 					background:
      // 						"var(--textProfile-background-color)",
      // 					color: "var(--primary-text-color)",
      // 					width: 32,
      // 					height: 32,
      // 					fontSize: "90%",
      // 					border: "1px solid var(--primary-text-color)",
      // 				}}
      // 				{...stringAvatar(params.value)}
      // 				onClick={(event) => {
      // 					event.stopPropagation();
      // 					navigate(`/task-management/${params?.id}`);
      // 				}}
      // 			/>
      // 		</Tooltip>
      // 	),
      // },
      {
        field: 'type',
        headerName: 'Type',
        renderCell: (params) => {
          if (params?.row?.isNew) {
            return params.value;
          }
          const option = getTaskTypeList?.find(
            (opt) => opt?._id === params.value || opt?.taskTypeName === params.value
          );
          if (!option) return null;
          return (
            <TaskTypeCell
              taskTypeName={option?.taskTypeName}
            />
          );
        },
      },
      { field: "ticketNo", headerName: "Ticket", flex: 1 },
      { field: "title", headerName: "Title", flex: 3 },
      { field: "description", headerName: "Description", flex: 3 },
      { field: "startDate", headerName: "Start Date", flex: 1.2 },
      { field: "dueDate", headerName: "Due Date", flex: 1.2 },
      { field: "plannedHour", headerName: "Planned Hours", flex: 1.3 },
      { field: "timeSpent", headerName: "Time Spent", flex: 1.2 },
      {
        field: "priority",
        headerName: "Priority",
        flex: 1.4,
        renderCell: (params) => (
          <Box sx={{ display: "flex", height: "25px" }}>
            <Typography sx={{ marginRight: "8px" }}>
              {getIcon(params.value)}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-text-color) !important",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1.4,
        sortable: true,
        renderCell: (params) => {
          const { statusValue, statusColor } = params.row;
          return (
            <Box
              sx={{
                backgroundColor:
                  statusValue && statusValue !== "0"
                    ? statusColor
                    : getStatusBackgroundColor(params.value), // Use the value of status
                minWidth: "70px",
                alignItems: "center",
                color: "#fff",
                borderRadius: "5px",
                height: "40px",
                display: "flex",
                padding: "0px 10px 0px 10px ",
                justifyContent: "center",
              }}
            >
              {params.value}
            </Box>
          );
        },
      },
    ],
    [getTaskTypeList]
  );

  useEffect(() => {
    if (EmployeeId) {
      memoizedEmployeeFetchData();
    }
  }, [memoizedEmployeeFetchData, EmployeeId]);

  // useEffect(() => {
  //   const storedValue = parseInt(
  //     window.localStorage.getItem("paginationNo"),
  //     10
  //   );
  //   const initialValue = numberArray.includes(storedValue) ? storedValue : 10;
  //   setRowsPerPage(initialValue);
  // }, []);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "69vh",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box>
          {EmpId ? (
            <InnerBoxOne>
              <TaskEfficiencyButton
                variant="contained"
                onClick={() => setTaskEfficiencyShow(!taskEfficiencyShow)}
              >
                {taskEfficiencyShow ? "Back To Task" : "Task Efficiency"}
              </TaskEfficiencyButton>
            </InnerBoxOne>
          ) : (
            <Typography
              color="primary"
              variant="h6"
              sx={{
                fontWeight: "bold",
                alignSelf: "center",
                borderBottom: "1px solid var(--table-border-color)",
                display: "flex",
              }}
            >
              {EmployeeId}:{" "}
              <Box
                sx={{
                  textTransform: "capitalize",
                  marginLeft: "7px",
                }}
              >
                {employeeName}
              </Box>
            </Typography>
          )}
          <br />
          {!taskEfficiencyShow && (
            <Box
              sx={{
                width: "100%",
                maxHeight: "fit-content",
                overflowX: "auto",
              }}
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    width: "350%",
                  },
                }}
              >
                <DataGrid
                  disableColumnMenu
                  rowHeight={43}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  aria-selected="false"
                  // rows={[]}
                  // columns={[]}
                  rows={taskDataRows}
                  columns={taskColumns}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        startDate: false,
                        // type: false
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize:
                          parseInt(
                            window.localStorage.getItem("paginationNo"),
                            10
                          ) || 10,
                      },
                    },
                  }}
                  onRowCountChange={(params) => {
                    if (params === 0) {
                      setDataGridHeight(true);
                    } else {
                      setDataGridHeight(false);
                    }
                  }}
                  onRowClick={(params) => {
                    handleDetailClick(params.row.ticketNo);
                  }}
                  onPaginationModelChange={(pageSize) => {
                    window.localStorage.setItem(
                      "paginationNo",
                      pageSize.pageSize
                    );
                  }}
                  onPageSizeChange={(pageSize) => {
                    window.localStorage.setItem("paginationNo", pageSize);
                  }}
                  pageSizeOptions={numberArray}
                  components={{
                    Toolbar: GridToolbar,
                    // noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  sx={{
                    height: dataGridHeight ? "230px" : "fit-content",
                    ".MuiDataGrid-filler": {
                      height: "0px !important",
                      display: "none",
                    },
                    color: "var(--primary-text-color)",
                    textTransform: "capitalize",
                    ".MuiDataGrid-columnHeaderTitle": {
                      textTransform: "none !important",
                    },
                    backgroundColor: "var(--subAccordian-background-color)",
                    ".MuiDataGrid-cell ": {
                      cursor: "pointer",
                      alignContent: "center",
                    },
                    ".MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    ".MuiDataGrid-columnHeader:focus": {
                      outline: "none",
                    },
                    ".MuiDataGrid-columnHeader": {
                      height: "48px !important",
                    },
                    ".MuiSvgIcon-fontSizeSmall": {
                      fill: "var(--primary-text-color) !important",
                    },
                    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
                    {
                      color: "var(--primary-text-color) !important",
                    },
                    ".MuiDataGrid-overlay": {
                      color: "var(--primary-text-color) !important",
                      backgroundColor: "var(--subAccordian-background-color)",
                    },
                    ".MuiInput-underline:before": {
                      borderBottom:
                        "1px solid var(--primary-text-color) !important",
                    },
                    // ".MuiSelect-icon": {
                    //   fill: "var(--secondary-text-color)",
                    // },
                    // ".MuiTablePagination-root": {
                    //   color: "var(--secondary-text-color)",
                    // },
                    // ".MuiDataGrid-root": {
                    //   color: "var(--secondary-text-color)",
                    // },
                    // ".MuiDataGrid-cellContent": {
                    //   color: "var(--secondary-text-color)",
                    //   fontSize: "14px",
                    // },
                    // ".MuiDataGrid-columnHeader": {
                    //   color: "var(--secondary-text-color)",
                    //   fontSize: "18px",
                    //   backgroundColor: "var(--primary-highlight-color)",
                    //   width: "100vh",
                    // },
                    // ".MuiDataGrid-footerContainer": {
                    //   color: "var(--secondary-text-color)",
                    // },
                    // ".MuiDataGrid-menuIconButton": {
                    //   color: "var(--secondary-text-color)",
                    // },
                    // ".MuiDataGrid-sortIcon": {
                    //   color: "var(--secondary-text-color)",
                    //   visibility: true,
                    // },
                    // "MuiDataGrid-virtualScroller": { color: "red" },
                    ".MuiDataGrid-actionsCell": { type: "actions" },
                    // ".MuiDataGrid-columnHeader:focus": {
                    //   outlineColor: "rgb(25, 118, 210)",
                    //   outlineStyle: "solid",
                    //   outlineWidth: "0px",
                    // },

                    //color: "var(--secondary-text-color)",
                    // // overflow: overlay
                    // ".MuiDataGrid-cell:focus": {
                    //   outlineColor: "rgb(25, 118, 210)",
                    //   outlineStyle: "solid",
                    //   outlineWidth: "0px",
                    // },
                  }}
                />
              </Box>
            </Box>
          )}
          {EmpId && taskEfficiencyShow ? (
            <EmployeeEfficiency
              EmpId={EmpId}
            // employeeName={employeeCall?.FirstName}
            // employeeEmpId={EmpId}
            // handleBackShowEfficiency={handleBackShowEfficiency}
            />
          ) : null}
          <TaskDialogBox
            unapprovedTask={true}
            open={openDialog}
            setOpenDialog={setOpenDialog}
            taskId={taskId}
            viewer={admin}
          />
        </Box>
      )}
    </>
  );
}

export default SelectedEmployeeTaskDataGrid;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  Box,
  Tooltip,
  Typography,
  Link,
  Slide,
  CardContent,
  Backdrop,
  Zoom,
  Chip
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Add, Edit, Delete, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { numberArray } from "../Constant/constant";
import {
  getSprint,
  addSprint,
  editSprints,
  deleteSprint,
  completedSprint,
} from "../../Services/TaskManageServices";
import {
  CancelButton,
  CloseIconButton,
  InnerBoxOneDialog,
  InnerBoxTwoDialog,
  ErrorTypography,
  DialogsActions,
  InputTextField,
  SideBox,
  CloseBox,
  MainBox,
  MainCard,
  InnerBoxOne,
  HedarBoxOne,
  ComplateSprintBox,
  CompletedSprint
} from "../../Styles/TaskManagement/TaskSprintStyle";
import {
  DeleteButton,
  MainBoxDialog,
} from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";

const TaskSprint = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [sprints, setSprints] = useState([]);
  const [newSprint, setNewSprint] = useState({
    name: "",
    duration: 1,
    startDate: "",
    endDate: "",
    goal: "",
  });
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [error, setError] = useState({});
  const [displayError] = useState("");
  const [dataGridHeight, setDataGridHeight] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  const [editSprint, setEditSprint] = useState({
    name: "",
    duration: 1,
    startDate: "",
    endDate: "",
    goal: "",
  });

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleRowSelectionChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel.slice(-1));
  };

  const handleOpenDeleteDialog = (rowData) => {
    setDeleteIndex(rowData._id);
    setDeleteDialogOpen(true);
  };

  const handleEditSprint = (rowData) => {
    setEditSprint({
      ...rowData,
      startDate: convertToDate(rowData.startDate),
      endDate: convertToDate(rowData.endDate),
    });
    setEditIndex(rowData._id);
    setEditDialogOpen(true);
  };

  const getAllSprint = async () => {
    try {
      const response = await getSprint();
      if (response.status === 200 || response.status === 201) {
        setSprints(response.data.sprints);
      } else {
        setSprints([])
      }
    } catch (error) {
      console.error("Error fetching Sprint:", error);
    }
  }

  const handleAddSprint = () => {
    const { name, duration, startDate, goal } = newSprint;

    if (name.trim() && startDate && goal.trim()) {
      const formatDate = (dateObj) => {
        const day = dateObj.getDate().toString().padStart(2, "0");
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
      };
      const calculatedEndDate = new Date(
        new Date(startDate).getTime() + duration * 7 * 24 * 60 * 60 * 1000
      );
      const formattedStartDate = formatDate(new Date(startDate));
      const formattedEndDate = formatDate(calculatedEndDate);

      const sprintData = {
        name,
        duration,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        goal,
      };

      addSprint(sprintData)
        .then((response) => {
          setSprints([...sprints, response.data.sprint]);
          setError({});
          setAddDialogOpen(false);
          setNewSprint({ name: "", duration: 1, startDate: "", goal: "" });
          dispatch(setToast(true));
          dispatch(setToastMsg("Sprint Added Successfully"));
          dispatch(setToastType("success"));
        })
        .catch((error) => console.error("Error adding sprint:", error));
    } else {
      console.error("All fields are required: Name, Start Date, and Goal");
    }
  };

  function convertToDate(dateString) {
    if (!dateString || typeof dateString !== "string") {
      console.error("Invalid date string");
      return null;
    }
    const parts = dateString.split("-");
    if (parts?.length !== 3) {
      console.error("Invalid date format");
      return null;
    }
    const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    const parsedDate = new Date(reformattedDate);
    if (isNaN(parsedDate)) {
      console.error("Invalid date value");
      return null;
    }
    return parsedDate;
  }

  const handleSaveEdit = () => {
    const { name, duration, startDate, goal } = editSprint;

    if (name.trim() && startDate && goal.trim()) {
      const updatedEndDate = new Date(
        new Date(startDate).getTime() + duration * 7 * 24 * 60 * 60 * 1000
      ).toISOString();
      editSprints(editIndex, {
        name,
        duration,
        startDate,
        endDate: updatedEndDate,
        goal,
      })
        .then((response) => {
          const updatedSprints = sprints.map((sprint) => {
            if (sprint._id === editIndex) {
              return {
                ...response.data.sprint,
                taskCount: sprint?.taskCount,
              };
            }
            return sprint;
          });
          setSprints(updatedSprints);
          setError({});
          setEditDialogOpen(false);
          dispatch(setToast(true));
          dispatch(setToastMsg("Sprint Updated Successfully"));
          dispatch(setToastType("success"));
        })
        .catch((error) => console.error("Error updating sprint:", error));
    } else {
      console.error("All fields are required!");
    }
  };

  const handleConfirmDelete = () => {
    const sprintId = deleteIndex;
    deleteSprint(sprintId)
      .then(() => {
        const updatedSprints = sprints.filter(
          (sprint) => sprint._id !== sprintId
        );
        setSprints(updatedSprints);
        setDeleteDialogOpen(false);
        dispatch(setToast(true));
        dispatch(setToastMsg("Sprint deleted successfully"));
        dispatch(setToastType("success"));
      })
      .catch((error) => console.error("Error deleting sprint:", error));
  };

  const handleCompletedSprint = async () => {
    try {
      const postData = { sprintId: selectedRows[0], isCompleted: true };
      const response = await completedSprint(JSON.stringify(postData))
      if (response.status === 200 || response.status === 201) {
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Sprint marked as completed successfully."));
        dispatch(setToastType("success"));
        setSelectedRows([]);
      }
      else if (response.status === 203) {
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Sprint contains tasks that are not completed."));
        dispatch(setToastType("warning"));
        setSelectedRows([]);
      }
    }
    catch (error) {
    }
  }

  const renderSprintDialogBox = (isEditMode) => {
    const handleSave = isEditMode ? handleSaveEdit : handleAddSprint;
    return (
      <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
        <MainBox>
          <Box sx={{ padding: "5px 0px 5px 18px" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {isEditMode ? "Edit Task Sprint" : "Add Task Sprint"}
            </Typography>
          </Box>
          <CloseBox>
            <IconButton
              onClick={() => {
                isEditMode ? setEditDialogOpen(false) : setAddDialogOpen(false);
                setError({});
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </CloseBox>
        </MainBox>
        <Box sx={{ padding: "8px 4px 8px 10px", marginRight: "8px" }}>
          <MainCard>
            <CardContent>
              <InputTextField
                label="Sprint Name"
                value={isEditMode ? editSprint.name : newSprint.name}
                isdarktheme={isDarkTheme ? "true" : "false"}
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({ ...editSprint, name: e.target.value })
                    : setNewSprint({ ...newSprint, name: e.target.value });
                  if (error["name"] && e.target.value !== "") {
                    setError((prevError) => {
                      const newError = { ...prevError };
                      delete newError["name"];
                      return newError;
                    });
                  }
                }}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <ErrorTypography>{error.name}</ErrorTypography>
              <InputTextField
                label="Duration (in weeks)"
                type="number"
                isdarktheme={isDarkTheme ? "true" : "false"}
                value={isEditMode ? editSprint.duration : newSprint.duration}
                onChange={(e) =>
                  isEditMode
                    ? setEditSprint({
                      ...editSprint,
                      duration: Number(e.target.value),
                    })
                    : setNewSprint({
                      ...newSprint,
                      duration: Number(e.target.value),
                    })
                }
                fullWidth
                margin="normal"
              />
              <InputTextField
                label="Start Date"
                type="date"
                name="startDate"
                isdarktheme={isDarkTheme ? "true" : "false"}
                sx={{
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: isDarkTheme ? "invert(1)" : "none",
                  },
                }}
                value={
                  isEditMode
                    ? dayjs(editSprint.startDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : dayjs(newSprint.startDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                }
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({
                      ...editSprint,
                      startDate: e.target.value,
                    })
                    : setNewSprint({ ...newSprint, startDate: e.target.value });
                  if (error["startDate"] && e.target.value !== "") {
                    setError((prevError) => {
                      const newError = { ...prevError };
                      delete newError["startDate"];
                      return newError;
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <ErrorTypography>{error.startDate}</ErrorTypography>
              <InputTextField
                label="End Date"
                type="date"
                name="endDate"
                isdarktheme={isDarkTheme ? "true" : "false"}
                sx={{
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: isDarkTheme ? "invert(1)" : "none",
                  },
                }}
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({ ...editSprint, endDate: e.target.value })
                    : setNewSprint({ ...newSprint, endDate: e.target.value });
                }}
                value={
                  isEditMode
                    ? dayjs(editSprint.endDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : dayjs(newSprint.endDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <InputTextField
                label="Goal"
                value={isEditMode ? editSprint.goal : newSprint.goal}
                isdarktheme={isDarkTheme ? "true" : "false"}
                onChange={(e) =>
                  isEditMode
                    ? setEditSprint({ ...editSprint, goal: e.target.value })
                    : setNewSprint({ ...newSprint, goal: e.target.value })
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <InnerBoxOne>
                <CancelButton
                  onClick={() => {
                    isEditMode
                      ? setEditDialogOpen(false)
                      : setAddDialogOpen(false);
                    setError({});
                  }}
                >
                  Cancel
                </CancelButton>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  {isEditMode ? "Update" : "Add"}
                </Button>
              </InnerBoxOne>
            </CardContent>
          </MainCard>
        </Box>
      </Box>
    );
  };

  const renderDeleteDialogBox = () => {
    const sprintName = sprints[deleteIndex]?.name || "this";
    return (
      <Box
        sx={{
          background: isDarkTheme ? "#121e2e" : "#ffffff",
          padding: "8px",
        }}
      >
        <MainBoxDialog>
          <Box>
            <DialogTitle
              sx={{
                fontWeight: "bold",
                padding: "0px 0px",
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              <InnerBoxOneDialog>
                <Box sx={{ padding: "8px 5px" }}>
                  <InnerBoxTwoDialog
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                      color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                    }}
                  >
                    Confirm Delete Sprint
                  </Box>
                </Box>
              </InnerBoxOneDialog>
            </DialogTitle>
          </Box>
          <Box>
            <CloseIconButton
              onClick={() => setDeleteDialogOpen(false)}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </MainBoxDialog>
        <DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
          <Typography
            sx={{
              color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
            }}
          >
            Are you sure you want to Delete{" "}
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              {sprintName}
            </span>{" "}
            sprint?
          </Typography>
        </DialogContent>
        <DialogsActions displayError={displayError}>
          <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
            <ErrorTypography>{displayError}</ErrorTypography>
          </Box>
          <Box>
            <CancelButton
              isdarktheme={isDarkTheme ? "true" : "false"}
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              variant="contained"
              onClick={handleConfirmDelete}
              component={Link}
              color="error"
            >
              Delete
            </DeleteButton>
          </Box>
        </DialogsActions>
      </Box>
    );
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "duration", headerName: "Duration (weeks)", flex: 1 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
    },
    {
      field: "taskCount",
      headerName: "Task Counts",
      flex: 1,
    },
    { field: "goal", headerName: "Goal", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {params?.row?.isCompleted ? (
              <Chip
                label="Completed"
                style={{ backgroundColor: "var(--approved-color)", color: "white" }}
                size="small"
                sx={{ fontWeight: "bold",fontSize:"11px" }}
              />
            ) : (
              <Chip
                label="In Progress"
                style={{ backgroundColor: "var(--pending-color)", color: "white" }}
                size="small"
                sx={{ fontWeight: "bold",fontSize:"11px" }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEditSprint(params.row)}
                sx={{ color: "var(--secondary-text-color)" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleOpenDeleteDialog(params.row)}
                sx={{ color: isDarkTheme ? "#d94a38" : "#c62828" }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getAllSprint();
  }, []);

  return (
    <Box>
      <HedarBoxOne>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpenAddDialog}
          sx={{ marginBottom: 1 }}
        >
          Add Sprint
        </Button>
        {selectedRows?.length > 0 && (
          <ComplateSprintBox>
            <Tooltip
              title="Completed Sprint"
              arrow
              disableInteractive
              TransitionComponent={Zoom}
            >
              <CompletedSprint
                variant="contained"
                color="primary"
                onClick={() => handleCompletedSprint()}
                sx={{ marginBottom: 1 }}
              >
                Completed Sprint
              </CompletedSprint>
            </Tooltip>
          </ComplateSprintBox>
        )}
      </HedarBoxOne>
      <Box
        sx={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              width: "290%",
            },
          }}
        >
          <DataGrid
            disableColumnMenu
            rowHeight={43}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            aria-selected="false"
            rows={sprints?.map((sprint) => ({
              ...sprint,
              id: sprint._id,
              startDate: new Date(sprint.startDate)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-"),
              endDate: new Date(sprint.endDate)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-"),
            }))}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize:
                    parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                    10,
                },
              },
            }}
            onRowCountChange={(params) => {
              if (params === 0) {
                setDataGridHeight(true);
              } else {
                setDataGridHeight(false);
              }
            }}
            checkboxSelection
            onPaginationModelChange={(pageSize) => {
              window.localStorage.setItem("paginationNo", pageSize.pageSize);
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              handleRowSelectionChange(newRowSelectionModel);
            }}
            rowSelectionModel={selectedRows}
            disableRowSelectionOnClick
            onPageSizeChange={(pageSize) => {
              window.localStorage.setItem("paginationNo", pageSize);
            }}
            pageSizeOptions={numberArray}
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              height: dataGridHeight ? "230px" : "fit-content",
              ".MuiDataGrid-filler": {
                height: "0px !important",
                display: "none",
              },
              color: "var(--primary-text-color)",
              textTransform: "capitalize",
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "none !important",
              },
              backgroundColor: "var(--subAccordian-background-color)",
              ".MuiDataGrid-cell ": {
                cursor: "pointer",
                alignContent: "center",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              ".MuiDataGrid-columnHeader": {
                height: "48px !important",
              },
              ".MuiInput-underline:before": {
                borderBottom: "1px solid var(--primary-text-color) !important",
              },
              ".MuiSvgIcon-fontSizeSmall": {
                fill: "var(--primary-text-color) !important",
              },
              ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
              {
                color: "var(--primary-text-color) !important",
              },
              ".MuiDataGrid-overlay": {
                color: "var(--primary-text-color) !important",
                backgroundColor: "var(--subAccordian-background-color)",
              },
              ".MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              ".MuiDataGrid-actionsCell": { type: "actions" },
              ".MuiCheckbox-root": {
                color: "var(--primary-text-color) !important",
                "&.Mui-checked": {
                  color: "var(--primary-color) !important",
                },
              },
              ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                visibility: "hidden",
              },

            }}
          />
        </Box>
      </Box>
      <Backdrop
        open={isAddDialogOpen}
        onClick={() => setAddDialogOpen(false)}
      />
      <Slide direction="left" in={isAddDialogOpen} mountOnEnter unmountOnExit>
        <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
          {renderSprintDialogBox(false)}
        </SideBox>
      </Slide>

      <Backdrop
        open={isEditDialogOpen}
        onClick={() => setEditDialogOpen(false)}
      />
      <Slide direction="left" in={isEditDialogOpen} mountOnEnter unmountOnExit>
        <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
          {renderSprintDialogBox(true)}
        </SideBox>
      </Slide>
      <DialogComponent
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        sx={{
          ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
          {
            backgroundColor: isDarkTheme
              ? "#0b1727 !important"
              : "#ffffff !important",
          },
        }}
        renderChildern={renderDeleteDialogBox}
      />
    </Box>
  );
};

export default TaskSprint;
import { Box, LinearProgress, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

export const TitleTextField = styled(TextField)(({ error, isdarktheme }) => ({
    flex: 2,
    marginLeft: "2%",
    borderBottom: error?.title ? "2px solid red" : "2px solid #ccc",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: error?.title ? "red" : isdarktheme === "true" ? "#e0e0e0e3" : "#ccc",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme ? "#e0e0e0e3" : "var(--primary-text-color)",
    },

    "& .MuiInputBase-root.MuiInput-root .Mui-error::before": {
        borderBottom: "none !important",
    },

    "& .MuiInputBase-root.MuiInput-root .Mui-error": {
        "&:before": {
            borderBottom: "none !important",
        },
    },
   
}));

export const TaskTypeSelect = styled(Select)(({ error, isdarktheme }) => ({
    minWidth: '12%',
    marginLeft: "5px",
    border: error ? '1px solid red' : '1px solid #ccc',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
        border: error ? '1px solid red' : '1px solid #ccc',
        borderRadius: '4px',
    },
    '& .MuiSelect-select': {
        padding: '6px 14px',
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
    '& .MuiMenuItem-root': {
        padding: '6px 14px',
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
}));

export const AssignToName = styled(Select)(({ isdarktheme }) => ({
    marginLeft: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    "& .MuiSelect-select": {
        padding: "6px 14px",
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
    "& .MuiMenuItem-root": {
        padding: "6px 14px",
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
}));

export const PrioritySelect = styled(Select)(({ isdarktheme }) => ({
    minWidth: '10%',
    marginLeft: "5px",
    border: '1px solid #ccc',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    '& .MuiSelect-select': {
        padding: '6px 14px',
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
    '& .MuiMenuItem-root': {
        padding: '6px 14px',
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
}));

const getStatusBackgroundColor = (status) => {
    switch (status) {
        case "In Progress":
            return "var(--pending-color)";
        case "Cancelled":
            return "var(--reject-color)";
        case "Completed":
            return "var(--approved-color)";
        case "Hold":
            return "var(--cancel-color)";
        case "To-Do":
            return "var(--primary-color)";
        default:
            return "var(--primary-color)";
    }
};

export const StatusSelect = styled(Select)(({ formData }) => ({
    marginLeft: "5px",
    minWidth: '10%',
    border: '1px solid #ccc',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    '& .MuiSelect-select': {
        padding: '6px 14px',
        background: formData?.statusValue
            ? formData?.statusColor
            : getStatusBackgroundColor(
                formData?.status
            ),
        color: "white !important",
    },
    '& .MuiMenuItem-root': {
        padding: '6px 14px',
    },
}));

export const CategorySelect = styled(Select)(({ isdarktheme }) => ({
    marginLeft: "5px",
    minWidth: '10%',
    border: '1px solid #ccc',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    '& .MuiSelect-select': {
        padding: '6px 14px',
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
    '& .MuiMenuItem-root': {
        padding: '6px 14px',
        color: isdarktheme === "true" ? "#e0e0e0e3" : "var(--primary-text-color)",
    },
}));

export const DueDatePicker = styled(DatePicker)(() => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
    },
    ".MuiInputBase-input": {
        display: "none",
    },
    ".MuiSvgIcon-root": {
        height: "20px",
    },
    ".MuiDatePicker-root .MuiInputAdornment-root": {
        width: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export const LinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 10px 5px 0px",
    borderRadius: "5px",
    width: '20%',
    height: '7px',
    backgroundColor: "var(--secondary-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--secondary-color)",
    },
    '@media (max-width: 600px)': {
        width: '100%',
    },
}));

export const SubTaskBox = styled(Box)(() => ({
    margin: "8px",
    display: 'flex',
    alignItems: "center",
    '@media (max-width: 600px)': {
        marginLeft: "4px",
    },
}));

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Tooltip, Zoom } from "@mui/material";

import LeaveAccordian from "../../LeaveManagement/LeaveAccordian";
import Loader from "../../Loader/Loader";
import LeaveDataGrid from "../Tables/LeaveDataGrid";
import { getLeavesData } from "../../../Services/LeaveManageServices";
import { LoadingBox, MainBox, InnerBoxTwo, InnerBoxOne, LeavesTypography } from "../../../Styles/LeaveManagement/LeaveManagementStyle"

const Leave = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.selectedActiveView.role);
  const token = window.localStorage.getItem("Token");
  const EmployeeLogin = user === "employee";

  const [absentData, setAbsentData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddLeave = () => {
    navigate("/leave/add");
  };

  const memoizedgetLeaveData = useMemo(() => {
    const getLeaveData = async () => {
      setIsLoading(true);
      try {
        const response = await getLeavesData();
        if (response.status === 200) {
          setIsLoading(false);
          const validLeaves = response.data.leaves.filter(leave =>
            leave.status !== "Cancelled" && leave.status !== "Rejected"
          );
          const validLeaveData = response.data.leaveData.filter(leave =>
            leave.status !== "Cancelled" && leave.status !== "Rejected"
          );
          setAbsentData(validLeaveData);
          setLeaveData(
            validLeaves.sort(
              (a, b) => new Date(b.startDate) - new Date(a.startDate)
            )
          );
        } else if (response.status === 401) {
          navigate("/");
        } else {
          setAbsentData([]);
          setLeaveData([]);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return getLeaveData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    memoizedgetLeaveData();
  }, [memoizedgetLeaveData]);

  return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <MainBox>
            <InnerBoxOne
              bottom={0}
              sx={{
                "@media (max-width: 600px)": {
                  flexDirection: "row",
                },
              }}
            >
              <LeavesTypography variant="h6">Leaves</LeavesTypography>
              <InnerBoxTwo>
                <Tooltip
                  title="Add Leave Details"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddLeave}
                  >
                    + Add Leaves
                  </Button>
                </Tooltip>
              </InnerBoxTwo>
            </InnerBoxOne>
            <LeaveDataGrid leaveData={leaveData} />
          </MainBox>
          {EmployeeLogin && absentData.length > 0 ? (
            <LeaveAccordian absentData={absentData} />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default Leave;

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAuthenticationData } from "../../Services/DashboardServices";

export const Authentication = () => {
    const [getData, setData] = useState("");

    const getAllData = async () => {
        try {
            const response = await getAuthenticationData();
            if (response.status === 200) {
                setData(response.data.message);
            } else {
                setData("");
            }
        } catch (error) {
            setData("");
        }
    }

    useEffect(() => {
        getAllData()
    }, [])

    return (
        <Box>
            Hello !! {getData}
        </Box>
    );
};

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import TaskDataGrid from "./TaskDataGrid";
import Loader from "../Loader/Loader";
import { getAllTaskData } from "../../Services/TaskManageServices";
import { AccordionsDetails, AccordionsSummary, EmpAccordionDetails, EmpNameAccordionsSummary, LoadingBox, NoTaskAllocatedBox } from '../../Styles/TaskManagement/EmployeeAccordionStyle'

const EmployeeAccordion = ({ change, setChange, taskAdded }) => {
  const navigate = useNavigate();
  // const allData = useSelector((state) => state.taskData.employeesNames);
  const user = useSelector((state) => state.selectedActiveView.role);

  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const currentAdminId = useSelector(
    (state) => state.adminData.adminData?.empId
  );
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [taskData, setTaskData] = useState([]);
  const [samelevel, setSamelevel] = useState([]);
  const [reportingEmployees, setReportingEmployees] = useState([]);
  const [empIds, setEmpIds] = useState([]);
  const [taskEdited, setTaskEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [updateTrue, setUpdateTrue] = useState(false);

  const memoizedgetTaskData = useMemo(() => {
    const getTaskData = async () => {
      const apiUser = currentEmployeeLogin
        ? "user"
        : adminLogin
          ? "admin"
          : null;
      if (apiUser) {
        setIsLoading(true);
        try {
          const response = await getAllTaskData(apiUser);
          if (response.status === 200 || response.status === 201) {
            setTaskData(response.data.taskData);
            setSamelevel(response.data?.samelevel);
            setEmpIds(response.data.EmpIds);
            setReportingEmployees(response.data?.reportingEmployees);
            setIsLoading(false);
          } else if (response.status === 401) {
            navigate("/");
          }
          else {
            setTaskData([]);
            setSamelevel([]);
            setEmpIds([]);
            setReportingEmployees([]);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    return getTaskData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminLogin, currentEmployeeLogin, taskAdded
  ]);

  useEffect(() => {
    // if (currentEmployeeLogin) {
    memoizedgetTaskData();
    // }
  }, [
    currentEmployeeLogin,
    memoizedgetTaskData,
    taskEdited,
    taskAdded,
    change
  ]);

  return (
    <Box>
      {isLoading ? (
        <LoadingBox adminlogin={adminLogin ? "true" : "false"}>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          {adminLogin ? (
            <>
              <Accordion defaultExpanded sx={{ marginBottom: "10px" }}>
                <AccordionsSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ fill: "var(--primary-text-color) !important" }}
                    />
                  }
                  aria-controls={`panel-content`}
                  id={`panel-header`}
                >
                  My Tasks
                </AccordionsSummary>
                <AccordionsDetails>
                  {taskData?.filter((state) => state?.empId === currentAdminId)
                    .length === 0 ? (
                    <NoTaskAllocatedBox>
                      No Task Allocated
                    </NoTaskAllocatedBox>
                  ) : (
                      <TaskDataGrid
                        taskData={taskData}
                        samelevel={samelevel}
                        change={change}
                        setChange={setChange}
                        currentAdminId={currentAdminId}
                        taskEdited={taskEdited}
                        setTaskEdited={setTaskEdited}
                      />
                  )}
                </AccordionsDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "10px" }}>
                <AccordionsSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ fill: "var(--primary-text-color) !important" }}
                    />
                  }
                  aria-controls={`panel-content`}
                  id={`panel-header`}
                >
                  Employees
                </AccordionsSummary>
                <AccordionsDetails>
                  {empIds?.map((option, index) =>
                    option.empId === currentAdminId ? null : (
                      <Accordion key={index} sx={{ marginBottom: "10px" }}>
                        <EmpNameAccordionsSummary
                          expandIcon={
                            <ExpandMoreIcon
                              style={{
                                color: "var(--primary-text-color) !important",
                              }}
                            />
                          }
                          aria-controls={`panel${index + 1}-content`}
                          id={`panel${index + 1}-header`}
                        >
                          {option.FirstName}
                        </EmpNameAccordionsSummary>
                        <EmpAccordionDetails>
                          {taskData?.filter(
                            (state) => state?.empId === option?.empId
                          ).length === 0 ? (
                            <NoTaskAllocatedBox>
                              No Task Allocated
                            </NoTaskAllocatedBox>
                          ) : (
                            <TaskDataGrid
                              taskData={taskData}
                              samelevel={samelevel}
                              change={change}
                              setChange={setChange}
                              EmpIdForFilter={option.empId}
                              taskEdited={taskEdited}
                              setTaskEdited={setTaskEdited}
                              taskAdded={taskAdded}
                            />
                          )}
                        </EmpAccordionDetails>
                      </Accordion>
                    )
                  )}
                </AccordionsDetails>
              </Accordion>
            </>
          ) : null}
          {currentEmployeeLogin ? (
            <>
              <Accordion defaultExpanded sx={{ marginBottom: "10px" }}>
                <AccordionsSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: "var(--primary-text-color) !important" }}
                    />
                  }
                  aria-controls={`panel-content`}
                  id={`panel-header`}
                >
                  My tasks
                </AccordionsSummary>
                <AccordionsDetails>
                  {taskData.filter((state) => state.empId === currentEmployeeId)
                    .length === 0 ? (
                    <NoTaskAllocatedBox>
                      No Task Allocated
                    </NoTaskAllocatedBox>
                  ) : (
                    <TaskDataGrid
                      taskData={taskData}
                      samelevel={samelevel}
                      change={change}
                      setChange={setChange}
                      currentEmployeeId={currentEmployeeId}
                      taskEdited={taskEdited}
                      setTaskEdited={setTaskEdited}
                    />
                  )}
                </AccordionsDetails>
              </Accordion>
              {empIds?.length === 0 ? null : (
                <>
                  <Accordion sx={{ marginBottom: "10px" }}>
                    <AccordionsSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "var(--primary-text-color) !important",
                          }}
                        />
                      }
                      aria-controls={`panel-content`}
                      id={`panel-header`}
                    >
                      UnApproved Task
                    </AccordionsSummary>
                    <AccordionsDetails>
                      {reportingEmployees.filter(
                        (state) => state.approved === false
                      ).length === 0 ? (
                        <NoTaskAllocatedBox>
                          No Task Allocated
                        </NoTaskAllocatedBox>
                      ) : (
                        <TaskDataGrid
                          unapprovedTask={true}
                          reportingEmployees={reportingEmployees}
                        />
                      )}
                    </AccordionsDetails>
                  </Accordion>
                  <Accordion sx={{ marginBottom: "10px" }}>
                    <AccordionsSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "var(--primary-text-color) !important",
                          }}
                        />
                      }
                      aria-controls={`panel-content`}
                      id={`panel-header`}
                    >
                      Reporting Person
                    </AccordionsSummary>
                    <AccordionsDetails>
                      {empIds?.map((option, index) =>
                        option.empId === currentAdminId ? null : (
                          <Accordion key={index} sx={{ marginBottom: "10px" }}>
                            <EmpNameAccordionsSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  style={{
                                    color:
                                      "var(--primary-text-color) !important",
                                  }}
                                />
                              }
                              aria-controls={`panel${index + 1}-content`}
                              id={`panel${index + 1}-header`}
                            >
                              {option.FirstName}
                            </EmpNameAccordionsSummary>
                            <EmpAccordionDetails>
                              {reportingEmployees?.filter(
                                (state) => state?.empId === option?.empId
                              ).length === 0 ? (
                                <NoTaskAllocatedBox>
                                  No Task Allocated
                                </NoTaskAllocatedBox>
                              ) : (
                                <TaskDataGrid
                                  taskData={taskData}
                                  samelevel={samelevel}
                                  change={change}
                                  setChange={setChange}
                                  reportingEmployees={reportingEmployees}
                                  ReportingPersonEmpId={option.empId}
                                  taskEdited={taskEdited}
                                  setTaskEdited={setTaskEdited}
                                />
                              )}
                            </EmpAccordionDetails>
                          </Accordion>
                        )
                      )}
                    </AccordionsDetails>
                  </Accordion>
                </>
              )}
            </>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default EmployeeAccordion;

import { axiosInstance } from './axiosInstance';

export const getLeaveAndAttendance = () => axiosInstance.get('/admin/leaveAndAttendence');

export const getEmployeeTime = () => axiosInstance.get('/admin/employeeInTime');

export const getEmployeeBirthDay = () => axiosInstance.get('/admin/birthdayCheck');

export const getEmployeLeaveBalance = () => axiosInstance.get('/user/LeaveBalance');

export const getWorkingDaysInThisMonthData = () => axiosInstance.get('/user/getMonthlyPresentData');

export const getWeekChartData = () => axiosInstance.get('/admin/employeeWeekInTime');

export const getEmployeesPunchInCount = () => axiosInstance.get('/admin/activeEmployees');

export const getDataEmployee = () => axiosInstance.get('/admin/employees');

export const getRoles = () => axiosInstance.get('/admin/token');

export const getEmployeesData = () => axiosInstance.get('/user/employeeDetail');

export const getTeamDetailData = () => axiosInstance.get('/user/teamDetails');

export const getAllFestival = () => axiosInstance.get('/admin/getFestival');

export const getAllEvent = () => axiosInstance.get('/admin/getEvent');

export const getNotificationByEmpId = (user, empId, pageNumber, pageSize) => axiosInstance.get(`/${user}/getNotificationByEmpId?empId=${empId}&pageNumber=${pageNumber ?? ""}&pageSize=${pageSize ?? ""}`);

export const upsertNotificationsMarkAsRead = (user, payload) => axiosInstance.post(`/${user}/notifications/mark-as-read`, payload);

export const deletenotification = (user, payload) => axiosInstance.post(`/${user}/notifications/delete`, payload);

export const getAuthenticationData = () => axiosInstance.get('/getAuthentication');

import {
  Typography,
  Button,
  styled,
  IconButton,
  Box,
  DialogActions,
  TextField,
  Card,
} from "@mui/material";

export const TitleTypography = styled(Typography)(() => ({
  color: "var(--primary-color)",
  fontWeight: "bold",
}));

export const TextTypography = styled(Typography)(() => ({
  color: "var(--secondary-text-color)",
}));

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
  // color: isdarktheme === "true" ? "#e0e0e0cc" : "#b2b5b9",
  // textTransform: "capitalize",
  // fontWeight: "bold",
  marginRight: "10px",
  borderRadius: "5px",
}));

export const SaveButton = styled(Button)(() => ({
  // fontWeight: "bold",
  textTransform: "capitalize",
  // fontSize: "18px",
}));

export const AddButton = styled(Button)(() => ({
  textTransform: "capitalize",
}));

export const InnerBoxOneDialog = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
  borderRadius: "1000px",
  padding: "8px",
}));

export const CloseIconButton = styled(IconButton)(
  ({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "8px 5px" : "0px 12px 0px 0px",
  })
);

export const ErrorTypography = styled(Typography)(() => ({
  color: "red",
  paddingLeft: "20",
  marginTop: "0px",
}));
export const DialogsActions = styled(DialogActions)(({ displayError }) => ({
  background: "var(--highlight-color)",
  boxShadow: "0px 2px 10px var(--dark-highlight-color)",
  display: "flex",
  justifyContent: displayError && "space-between",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: displayError && "column",
    justifyContent: displayError && "flex-end",
  },
}));

export const InputTextField = styled(TextField)(({ isdarktheme }) => ({
  ".MuiOutlinedInput-input": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    borderColor: "white",
  },
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  ".MuiInputLabel-root": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  ".MuiInputLabel-root.Mui-focused": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  svg: {
    fill: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#484848",
}));

export const SideBox = styled(Box)(({ isdarktheme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  width: "40%",
  height: "100vh",
  background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
  borderRadius: "5px",
  zIndex: 999,
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  "@media (max-width: 600px)": {
    width: "100%",
  },
}));

export const MainCard = styled(Card)(() => ({
  boxShadow: 4,
  width: "100%",
  marginTop: "10px",
  background: "var(--background-table-sidebar-card-color)",
}));

export const MainBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 13px 10px -20px #111",
  borderBottom: "1px solid var(--table-border-color)",
  color: "var(--secondary-text-color)",
  marginBottom: "5px",
}));

export const CloseBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "0px 10px 5px 0px",
}));

export const InnerBoxOne = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const HedarBoxOne = styled(Box)(() => ({
  borderBottom: "1px solid var(--table-border-color)",
  marginBottom: "20px",
  boxShadow: "0px 13px 10px -20px #111",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const ComplateSprintBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "0px 5px 0px 5px",
  "@media (max-width: 600px)": {
    padding: "0px 0px 0px 0px",
  },
}))

export const AddSprintBox = styled(Box)(() => ({
  padding: "5px 0px 0px 18px",
  display: "flex",
  "@media (max-width: 600px)": {
    padding: "0px 0px 0px 0px",
  },
}));

export const CompletedSprint = styled(Button)(() => ({
  backgroundColor: "var(--secondary-color)",
  "&:hover": {
    color: "none",
    backgroundColor: "var(--secondary-color-H)",
  },
  color: "#ffffff",
  marginLeft: "5px",
}));


import { Box, Button, Card, Checkbox, TableCell, TableHead, Typography, styled } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";

export const MainBox = styled(Box)(() => ({
  overflow: "auto",
  margin: "0px 0px 25px 0px"
}))

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "65vh",
}))

export const MainCard = styled(Card)(() => ({
  height: "100%",
  borderRadius: "5px",
  backgroundColor: "var(--background-table-sidebar-card-color)",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const InnerCardOne = styled(Card)(() => ({
  borderRadius: "5px",
  backgroundColor: "var(--background-table-sidebar-card-color)",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const InnerBoxOne = styled(Box)(({ ismobile }) => ({
  margin: "10px",
  display: ismobile !== "true" && "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}))

export const TitleTypography = styled(Typography)(({ ismobile }) => ({
  color: "var(--primary-color)",
  display: "inline",
  fontWeight: "bold",
  fontSize: "130%",
  paddingLeft: ismobile === "true" ? "2px" : "14px"
}))

export const CustomButton = styled(Button)(({ ismobile }) => ({
  marginRight: ismobile === "true" ? "0%" : "1.5%",
  marginLeft: ismobile === "true" ? "0%" : "3%",
  padding: ismobile === "true" ? "6px 7px" : "6px 16px"
}))

export const AttendanceTabBox = styled(Box)(() => ({
  borderRadius: "5px",
  minHeight: "392px",
}))

export const AttendanceTabMainBox = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  position: "relative"
}))

export const TableHeads = styled(TableHead)(() => ({
  background: "var(--primary-highlight-color)",
  height: "48px",
  position: "sticky",
  top: 0,
  zIndex: 1,
}))

export const TableCells = styled(TableCell)(({ tablebodycell }) => ({
  ...tablebodycell,
  fontWeight: "500 !important",
}))

export const LeaveTableCells = styled(TableCell)(({ tablebodycell }) => ({
  ...tablebodycell,
  fontWeight: "500 !important",
  textAlign: "center",
  paddingRight: "5%",
}))

export const CustomCheckbox = styled(Checkbox)(({ isdarktheme }) => ({
  padding: "2%",
  height: "55%",
  color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
  // color: "var(--selected-checkbox)",
}))

export const ActionTableCells = styled(TableCell)(() => ({
  textAlign: "center",
  fontSize: "18px",
  letterSpacing: "1px",
  fontWeight: "8px",
  color: "var(--primary-text-color)",
  paddingLeft: "30px",
  paddingRight: "30px",
}))

export const NoRecordFoundTypography = styled(Typography)(() => ({
  color: "var(--secondary-text-color)",
  display: "flex",
  justifyContent: "center",
}))

export const NoEmpFoundTableCell = styled(TableCell)(() => ({
  color: "var(--secondary-text-color)",
  textAlign: "center",
}))


export const VisibilityIcons = styled(VisibilityIcon)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: "1.5rem",
  color: "var(--secondary-text-color)",
  cursor: "pointer",
}))

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
  height: dataGridHeight ? "230px" : "fit-content",
  ".MuiDataGrid-filler": {
    height: "0px !important",
    display: "none",
  },
  textTransform: "capitalize",
  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "none !important",
  },
  backgroundColor: "var(--subAccordian-background-color)",
  ".MuiDataGrid-cell ": {
    cursor: "pointer",
    alignContent: "center",
  },
  ".MuiSvgIcon-fontSizeSmall": {
    fill: "var(--primary-text-color) !important",
  },
  ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
  {
    color: "var(--primary-text-color) !important",
  },
  ".MuiDataGrid-overlay": {
    color: "var(--primary-text-color) !important",
    backgroundColor: "var(--subAccordian-background-color)",
  },
  ".MuiInput-underline:before": {
    borderBottom: "1px solid var(--primary-text-color) !important",
  },
  ".MuiSelect-icon": {
    fill: "var(--secondary-text-color)",
  },
  ".MuiTablePagination-root": {
    color: "var(--secondary-text-color)",
  },
  ".MuiDataGrid-root": {
    color: "var(--secondary-text-color)",
  },
  ".MuiDataGrid-cellContent": {
    color: "var(--secondary-text-color)",
    fontSize: "14px",
  },
  ".MuiDataGrid-columnHeader": {
    color: "var(--secondary-text-color)",
    fontSize: "18px",
    backgroundColor: "var(--primary-highlight-color)",
    width: "100vh",
    height: "48px !important",
  },
  ".MuiDataGrid-footerContainer": {
    color: "var(--secondary-text-color)",
  },
  ".MuiDataGrid-menuIconButton": {
    color: "var(--secondary-text-color)",
  },
  ".MuiDataGrid-sortIcon": {
    color: "var(--secondary-text-color)",
    visibility: true,
  },
  ".MuiDataGrid-actionsCell": { type: "actions" },
  ".MuiDataGrid-columnHeader:focus": {
    outlineColor: "rgb(25, 118, 210)",
    outlineStyle: "solid",
    outlineWidth: "0px",
    outline: "none",
  },

  color: "var(--secondary-text-color)",
  // overflow: overlay
  ".MuiDataGrid-cell:focus": {
    outlineColor: "rgb(25, 118, 210)",
    outlineStyle: "solid",
    outlineWidth: "0px",
    outline: "none",
  },
  ".MuiCheckbox-root": {
    color: "var(--primary-text-color) !important",
    "&.Mui-checked": {
      color: "var(--primary-color) !important",
    },
  },
  ".MuiDataGrid-scrollbar": {
    height: "auto !important",
  },
}))

export const FooterBox = styled(Box)(() => ({
  // position: "absolute",
  bottom: 0,
  right: 30,
  alignSelf: "flex-end",
}))
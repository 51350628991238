import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import {
  Box,
  Button,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  List,
  Tooltip,
  ListItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import {
  taskTypeOptions,
  taskStatusList,
  priorityArray,
} from "../Constant/constant";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";
import { TypeCommon, getIcon } from "../ReusableComponents/UtilityFunctions";
import Loader from "../Loader/Loader";
import { errorMessage } from "../../Services/axiosInstance";
import { addTags, getTaskType, upsertTask, fetchCategories, getSprint, getAllTags } from "../../Services/TaskManageServices";
import { getProjectsName } from "../../Services/ProjectManageServices";
import { getEmployee } from "../../Services/EmployeeManageServices";
import {
  LoadingBox,
  AssignByTextField,
  TitleBox,
  CloseBox,
  MainCard
} from "../../Styles/TaskManagement/AddTaskFormStyle";

function AddTaskForm({ onClose, setChange, change, taskAdded, setTaskAdded }) {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const newTask = useSelector((state) => state.taskData.newTask);
  const employeeCurrent = useSelector((state) => state.addEmployee.current);
  const adminData = useSelector((state) => state.adminData.adminData);
  const user = useSelector((state) => state.selectedActiveView.role);
  const taskPlannedHours = useSelector((state) => state.taskData.plannedHours);
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [projects, setProjects] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [parentTaskList, setParentTaskList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    title: null,
    date: null,
    dueDate: null,
    category: null,
    type: null,
    parentTaskId: null,
    taskType: null,
    project: null,
    priority: null,
    description: null,
    status: null,
    statusValue: null,
    plannedHours: null,
    billableHours: null,
    actualHours: null,
    assignToName: null,
    tags: null,
    sprint: null,
  });
  const [displayMessageError, setDisplayMessageError] = useState("");
  const [formData, setFormData] = useState({
    title: newTask?.title || "",
    date: new Date().toISOString().split("T")[0],
    category: newTask?.category || "",
    type: newTask?.type || "",
    parentTaskId: newTask?.parentTaskId || "",
    taskType: newTask?.taskType || "",
    project: newTask?.project || "",
    priority: newTask?.priority || "",
    description: newTask?.description || "",
    status: newTask?.status || taskStatusList[0],
    statusColor: "",
    // status: newTask?.status || "",
    statusValue: newTask?.statusValue || 0,
    plannedHours: taskPlannedHours || "",
    billableHours: "",
    assignToName: newTask?.assignToName || "",
    tags: newTask?.tags || "",
    dueDate: new Date().toISOString().split("T")[0],
    sprint: newTask?.sprint || "",
  });
  const [assignEmpId, setAssignEmpId] = useState("");
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsbillable, setSuggestionsBillable] = useState([]);
  const [categories, setCategories] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [sprints, setSprints] = useState([]);
  const [taskAddingMessage, setTaskAddingMessage] = useState("");

  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { font: [] },
        { size: [] },
        { color: [] },
        { background: [] },
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "link",
        "code-block",
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        { indent: "+1" },
        { script: "sub" },
        { script: "super" },
      ],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "color",
    "background",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "link",
    "code-block",
    "list",
    "bullet",
    "indent",
    "script",
  ];

  const setClose = () => {
    onClose();
  };

  const renderAsterisk = () => {
    return <span style={{ color: "orange", fontSize: "20px" }}>*</span>;
  };

  function getuser() {
    if (currentEmployeeLogin) {
      return "user";
    } else if (adminLogin) {
      return "admin";
    }
  }

  function getName() {
    if (currentEmployeeLogin) {
      return employeeCurrent?.firstName;
    } else if (adminLogin) {
      return adminData?.firstName;
    }
  }

  function getEmpId() {
    if (currentEmployeeLogin) {
      return employeeCurrent?.empId;
    } else if (adminLogin) {
      return adminData?.empId;
    }
  }

  const name = getName();
  const currentEmployeeId = getEmpId();

  const handleActualHoursChange = (event, taskType) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [taskType === "planned" ? "plannedHours" : "billableHours"]: value,
    }));
    generateSuggestions(value, taskType);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));

    setFormData((prevData) => {
      const newStatus =
        name === "statusValue"
          ? statusList.find((item) => item?._id === value)?.status
          : name === "status"
            ? value
            : prevData.status;
      const color =
        name === "statusValue"
          ? statusList.find((item) => item?._id === value)?.color
          : prevData.statusColor;
      return {
        ...prevData,
        [name]: value,
        status: newStatus,
        statusColor: color,
      };
    });
    if (name === "project") {
      const list = projects?.find((item) => item?._id === value)?.parentTaskList
      setParentTaskList(list ?? [])
      const data = projects?.find((p) => p?._id === value && p?.workFlowId);
      if (data) {
        setStatusList(data?.statusData ?? []);
        setProjectId(data?._id);
      } else {
        setStatusList([]);
        setProjectId("");
      }
    }
  };

  const fetchTags = async () => {
    try {
      const response = await getAllTags();
      if (response.status === 200 || response.status === 201) {
        const fetchedTags = response?.data?.map((tag) => ({
          _id: tag._id,
          title: tag.title,
          color: tag.color,
        }));
        setOptions(fetchedTags);
      } else {
        setOptions([])
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const fetchProjectName = async () => {
    try {
      const response = await getProjectsName();
      if (response.status === 200) {
        setProjects(response.data.projects);
      } else {
        setProjects([]);
      }
    } catch (error) {
      console.error("Error fetching project names:", error);
    }
  };

  const fetchTypeOptions = async () => {
    try {
      const response = await getTaskType();
      if (response.status === 200) {
        const tasksType = response?.data?.taskTypes?.find(type => type?.taskTypeName === "Tasks");
        if (tasksType) {
          setFormData(prevData => ({
            ...prevData,
            type: tasksType?._id
          }));
        }
        setTypeOptions(response.data.taskTypes);
      } else {
        setTypeOptions([]);
      }
    } catch (error) {
      console.error("Error fetching project names:", error);
    }
  };

  const fetchEmployeeName = async () => {
    try {
      const response = await getEmployee();
      if (response.status === 200 || response.status === 201) {
        setEmployeeList(response.data.namesData);
      } else {
        setEmployeeList([]);
      }
    } catch (error) { }
  };

  const getCategories = async () => {
    try {
      const response = await fetchCategories();
      if (response.status === 200 || response.status === 201) {
        setCategories(response?.data);
      }
      else {
        setCategories([])
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getAllSprint = async () => {
    try {
      const response = await getSprint();
      if (response.status === 200 || response.status === 201) {
        const filteredSprints = response?.data?.sprints?.filter((sprint) => !sprint.isCompleted)?.map((sprint) => ({
          _id: sprint._id,
          name: sprint.name,
        }));
        setSprints(filteredSprints);
      } else {
        setSprints([])
      }
    } catch (error) {
      console.error("Error fetching Sprint:", error);
    }
  }
  const formatTime = (timeString) => {
    const hoursRegex = /(\d+)\s*(h|hours)/i;
    const minutesRegex = /(\d+)\s*(m|minutes)/i;
    let hours = 0;
    let minutes = 0;

    if (hoursRegex.test(timeString)) {
      hours = parseInt(timeString.match(hoursRegex)[1], 10);
    }

    if (minutesRegex.test(timeString)) {
      minutes = parseInt(timeString.match(minutesRegex)[1], 10);
    }

    const totalMinutes = hours * 60 + minutes;
    const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(
      2,
      "0"
    );
    const formattedMinutes = String(totalMinutes % 60).padStart(2, "0");
    return totalMinutes > 0
      ? `${formattedHours}:${formattedMinutes}`
      : timeString ?? "00:00";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];

    if (!formData.title) {
      errors.title = "Title is required";
    }

    if (!formData.category) {
      errors.category = "Task category is required";
    }
    if (!formData.type) {
      errors.type = "Type is required";
    }
    if (!formData.taskType) {
      errors.taskType = "Task type is required";
    }
    if (!formData.project) {
      errors.project = "Project is required";
    }
    if (!formData.priority) {
      errors.priority = "Priority is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    if (!formData.status) {
      errors.status = "Status is required";
    }
    if (projectId && !formData.statusValue) {
      errors.statusValue = "Status is required";
    }

    if (!formData.plannedHours) {
      errors.plannedHours = "Planned hours is required";
    } else {
      const plannedHoursValue = formatTime(formData.plannedHours);
      if (plannedHoursValue === "00:00") {
        errors.plannedHours = "Planned hours cannot be zero";
      } else {
        formData.plannedHours = plannedHoursValue;
      }
    }

    if (formData.billableHours) {
      const billableHoursValue = formatTime(formData.billableHours);
      if (billableHoursValue) {
        formData.billableHours = billableHoursValue;
      }
    }

    if (!formData.date) {
      errors.date = "Date is required";
    } else {
      const inputDate = new Date(formData.date);
      const currentDate = new Date();
      if (
        inputDate.getDate() < currentDate.getDate() ||
        inputDate.getMonth() < currentDate.getMonth() ||
        inputDate.getFullYear() < currentDate.getFullYear()
      ) {
        errors.date = "Date must not be older than today";
      }
    }
    if (!formData.dueDate) {
      errors.dueDate = "Due-date is required";
    } else {
      const inputDate = new Date(formData.dueDate);
      const currentDate = new Date(formData.date);
      if (
        !(
          ((inputDate.getDate() > currentDate.getDate() ||
            inputDate.getDate() === currentDate.getDate()) &&
            (inputDate.getMonth() > currentDate.getMonth() ||
              inputDate.getMonth() === currentDate.getMonth()) &&
            (inputDate.getFullYear() > currentDate.getFullYear() ||
              inputDate.getFullYear() === currentDate.getFullYear())) ||
          (inputDate.getDate() < currentDate.getDate() &&
            inputDate.getMonth() > currentDate.getMonth() &&
            (inputDate.getFullYear() > currentDate.getFullYear() ||
              inputDate.getFullYear() === currentDate.getFullYear()))
        )
      ) {
        errors.dueDate = "Due-date should set for a time after task start date";
      }
    }
    setError({
      title: errors.title,
      date: errors.date,
      dueDate: errors.dueDate,
      category: errors.category,
      type: errors.type,
      parentTaskId: errors.parentTaskId,
      taskType: errors.taskType,
      project: errors.project,
      priority: errors.priority,
      description: errors.description,
      status: errors.status,
      statusValue: errors.statusValue,
      plannedHours: errors.plannedHours,
      actualHours: errors.actualHours,
      billableHours: errors.billableHours,
      assignToName: errors.assignToName,
      tags: errors.tags,
      sprint: errors.sprint,
    });
    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      // setIsLoading(true);
      try {
        setTaskAddingMessage("Adding the Task...");
        setDisplayMessageError("");
        const getUser = getuser();
        const postData = JSON.stringify({
          formData,
          currentEmployeeId,
          assignEmpId,
        });
        const response = await upsertTask(getUser, postData);
        if (response.status === 200 || response.status === 201) {
          setTaskAdded(!taskAdded);
          // setIsLoading(false);
          setTaskAddingMessage("");
          // dispatch(setPlannedHours(""));
          // dispatch(setNewTask([]));
          onClose();
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          // setIsLoading(false);
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Task added."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        // setIsLoading(false);
        setDisplayMessageError(errorMessage ?? "");
        setTaskAddingMessage(""); 
      }
    }
    return errors;
  };

  const handleSelect = (event) => {
    setError((prevErrors) => ({
      ...prevErrors,
      assignToName: null,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      assignToName: event.target.value,
    }));
    if (event.target.value) {
      const selectedEmployee = employeeList?.find(
        (employee) => employee.firstName === event.target.value
      );
      if (selectedEmployee) {
        setAssignEmpId(selectedEmployee.empId);
      }
    }
  };

  const handleAddTag = async (tag) => {
    const trimmedTag = tag.trim();

    if (!trimmedTag) {
      setError({ tags: "This tag already exists" });
      return;
    }

    if (tags.some((t) => t.title === trimmedTag)) {
      setInputValue("");
      setError({ tags: "This tag already exists" });
      return;
    }

    try {
      const existingTag = options.find((t) => t.title === trimmedTag);
      if (existingTag) {
        const updatedTags = [...tags, existingTag];
        setTags(updatedTags);
        setFormData((prevData) => ({
          ...prevData,
          tags: updatedTags.map((t) => t._id),
        }));
        setInputValue("");
        setError({ tags: null });
        return;
      }

      const randomColor = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;
      const postData = JSON.stringify({
        title: trimmedTag,
        color: randomColor,
      });
      const response = await addTags(postData);
      if (response.status === 200 || response.status === 201) {
        const newTag = await response.data;
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        setFormData((prevData) => ({
          ...prevData,
          tags: updatedTags.map((t) => t._id),
        }));
        setInputValue("");
        setError({ tags: null });
        setOptions((prevOptions) => [...prevOptions, newTag]);
        return;
      } else if (response.status === 400) {
        setError({ tags: "Invalid tag submission" });
      } else {
        setError("Failed to add tag");
      }
    } catch (error) {
      setError({ tags: "Error adding tag to the database" });
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    const updatedTags = tags.filter((tag) => tag.title !== tagToDelete.title);
    setTags(updatedTags);
    setFormData((prevData) => ({
      ...prevData,
      tags: updatedTags,
    }));
  };

  const generateSuggestions = (input, type) => {
    let options = [];
    const hoursMatch = input.match(/(\d+)\s*(h|hours)/i);
    const minutesMatch = input.match(/(\d+)\s*(m|minutes)/i);

    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    if (!isNaN(input) && input.trim() !== "") {
      const numericValue = parseInt(input, 10);
      options.push(`${numericValue} hour${numericValue > 1 ? "s" : ""}`);
    }

    if (minutes > 0) {
      options.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    }

    if (hours > 0 || minutes > 0) {
      options.push(
        `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${minutes > 1 ? "s" : ""
        }`
      );
    }

    if (type === "planned") {
      setSuggestions(options);
    } else if (type === "billable") {
      setSuggestionsBillable(options);
    }
  };

  const handleSuggestionClick = (suggestion, type) => {
    const currentInput =
      type === "planned" ? formData.plannedHours : formData.billableHours;
    const hoursMatch = currentInput.match(/(\d+)\s*(h|hours)/i);
    const minutesMatch = suggestion.match(/(\d+)\s*(m|minutes)/i);

    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    const isHourSuggestion = suggestion.toLowerCase().includes("hour");
    const isMinuteSuggestion = suggestion.toLowerCase().includes("minute");

    if (isHourSuggestion) {
      hours = parseInt(suggestion) || hours;
    } else if (isMinuteSuggestion) {
      minutes = parseInt(suggestion) || minutes;
    }

    const updatedHours = `${hours} hour${hours > 1 ? "s" : ""
      } ${minutes} minute${minutes > 1 ? "s" : ""}`;

    setFormData((prevData) => ({
      ...prevData,
      [type === "planned" ? "plannedHours" : "billableHours"]: updatedHours,
    }));

    if (type === "planned") {
      setSuggestions([]);
    } else if (type === "billable") {
      setSuggestionsBillable([]);
    }
  };

  const handleDescriptionChange = (content, delta, source, editor) => {
    const htmlValue = editor.getHTML().trim();
    if (htmlValue === "<p><br></p>") {
      setFormData((prevData) => ({
        ...prevData,
        description: "",
      }));
      setError((prevErrors) => ({
        ...prevErrors,
        description: "Description is required",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        description: htmlValue,
      }));
      setError((prevErrors) => ({
        ...prevErrors,
        description: null,
      }));
    }
  };

  const CustomLabel = ({ text, tooltipText }) => {
    return (<>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {text}
        {text === "Planned Hours" ? <span style={{ color: "orange", fontSize: "20px" }}>*</span>: ""}
        <Tooltip
          placement="top"
          title={
            <Typography sx={{ fontSize: "14px" }}>
              {tooltipText}
            </Typography>
          }
          arrow
        >
          <HelpOutlineIcon sx={{ fontSize: "20px", cursor: "pointer" }} color="primary" />
        </Tooltip>
      </Box>
    </>
    );
  };

  useEffect(() => {
    fetchTags();
    fetchProjectName();
    fetchTypeOptions();
    fetchEmployeeName();
    getCategories();
    getAllSprint();
  }, []);

  return (
    <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <>
          <TitleBox>
            {/* Box for items on the left */}
            <Box sx={{ padding: "5px 0px 5px 18px" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Add Task
              </Typography>
            </Box>
            <CloseBox>
              <IconButton onClick={onClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </CloseBox>
          </TitleBox>
          <Box sx={{ padding: "8px 4px 8px 10px", marginRight: "8px" }}>
            <MainCard>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <AssignByTextField
                        fullWidth
                        disabled
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                        }}
                        sx={{
                          ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "var(--secondary-text-color)",
                            },
                          svg: {
                            fill: "var(--secondary-text-color)",
                          },
                        }}
                        value={name}
                        label="Assign By"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        select
                        label="Assign To (Optional)"
                        name="assignToName"
                        value={formData.assignToName}
                        onChange={(event, newValue) => handleSelect(event)}
                        multiline
                        variant="outlined"
                      >
                        {employeeList?.map((option) => (
                          <MenuItem
                            key={option.firstName}
                            value={option.firstName}
                          >
                            {option.firstName}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography color="error">
                        {error.assignToName}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                        }}
                        label={
                          <span>
                            Task Title
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        variant="outlined"
                      />
                      <Typography color="error">{error.title}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{
                          '& input[type="date"]::-webkit-calendar-picker-indicator':
                            {
                              filter: isDarkTheme ? "invert(1)" : "none",
                            },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* Add your icon here if needed */}
                            </InputAdornment>
                          ),
                        }}
                        label={
                          <span>
                            Task date
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="date"
                        type="date"
                        value={formData.date}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{
                          min: today, // Disable past dates
                        }}
                      />

                      <Typography color="error">{error.date}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                        }}
                        sx={{
                          '& input[type="date"]::-webkit-calendar-picker-indicator':
                            {
                              filter: isDarkTheme ? "invert(1)" : "none",
                            },
                        }}
                        label={
                          <span>
                            Task dueDate
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="dueDate"
                        type="date"
                        value={formData.dueDate}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{
                          min: today, // Disable past dates
                        }}
                      />

                      <Typography color="error">{error.dueDate}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        select
                        label={
                          <span>
                            Task Category
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        multiline
                        variant="outlined"
                      >
                        {categories?.map((option) => (
                          <MenuItem key={option._id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.category}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label={
                          <span>
                            Task Type
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="taskType"
                        value={formData.taskType}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {taskTypeOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.taskType}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label={
                          <span>
                            Project
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="project"
                        value={formData.project}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {projects?.map((option) => (
                          <MenuItem key={option?._id} value={option?._id}>
                            {option?.project}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.project}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label={
                          <span>
                            Priority
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="priority"
                        value={formData.priority}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {priorityArray?.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Box sx={{ display: "flex", height: "23px" }}>
                              <Typography sx={{ marginRight: "8px" }}>
                                {getIcon(option)}
                              </Typography>
                              <Typography>{option}</Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.priority}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TypeCommon
                        isDarkTheme={isDarkTheme}
                        formData={formData}
                        typeOptions={typeOptions}
                        handleChange={handleChange}
                      />
                      <Typography color="error">{error.type}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        value={
                          formData.parentTaskId
                            ? parentTaskList?.find(
                                (task) => task?._id === formData?.parentTaskId
                              )
                            : null
                        }
                        onChange={(event, newValue) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            parentTaskId: newValue ? newValue?._id : "",
                          }))
                        }
                        options={parentTaskList}
                        getOptionLabel={(option) =>
                          option
                            ? `${option?.ticketNo} : ${
                                option?.title?.length > 30
                                  ? option?.title?.slice(0, 30) + "..."
                                  : option?.title
                              }`
                            : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?._id === value?._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={
                              <CustomLabel
                                text="Parent Task (Optional)"
                                tooltipText="Select a parent task if this is a subtask. Leave blank if not applicable."
                                isDarkTheme={isDarkTheme}
                              />
                            }
                            variant="outlined"
                            sx={{
                              "& fieldset legend > span": {
                                paddingLeft: "2px",
                                paddingRight: "0px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                      <Typography color="error">
                        {error.parentTaskId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Sprint (Optional)"
                        name="sprint"
                        value={formData.sprint}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {sprints.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "23px",
                              }}
                            >
                              <Typography>{option.name}</Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.sprint}</Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Planned Hours"
                        name="plannedHours"
                        value={formData.plannedHours}
                        onChange={handleActualHoursChange}
                        variant="outlined"
                      ></TextField>
                      <Typography color="error">
                        {error.plannedHours}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={
                          <CustomLabel
                            text="Planned Hours"
                            tooltipText="Enter the planned hours in (HH:mm) format"
                            isDarkTheme={isDarkTheme}
                          />
                        }
                        name="plannedHours"
                        value={formData.plannedHours}
                        onChange={(e) => handleActualHoursChange(e, "planned")}
                        variant="outlined"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          "& fieldset legend > span": {
                            paddingLeft: "2px",
                            paddingRight: "0px",
                          },
                        }}
                      />
                      <Typography color="error">
                        {error.plannedHours}
                      </Typography>
                      {suggestions.length > 0 &&
                        (formData?.plannedHours.includes("h") ||
                          formData?.plannedHours.includes("m")) && (
                          <List
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginTop: "4px",
                            }}
                          >
                            {suggestions.map((option, index) => (
                              <ListItem
                                button
                                key={index}
                                onClick={() =>
                                  handleSuggestionClick(option, "planned")
                                }
                                sx={{
                                  color: isDarkTheme ? "#b7b9bc" : "#5e6e85",
                                }}
                              >
                                {option}
                              </ListItem>
                            ))}
                          </List>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={
                          <CustomLabel
                            text="Billable Hours (Optional)"
                            tooltipText="Enter the billable hours in (HH:mm) format"
                            isDarkTheme={isDarkTheme}
                          />
                        }
                        name="billableHours"
                        value={formData.billableHours}
                        onChange={(e) => handleActualHoursChange(e, "billable")}
                        variant="outlined"
                        autoComplete="off"
                        sx={{
                          "& fieldset legend > span": {
                            paddingLeft: "2px",
                            paddingRight: "0px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Typography color="error">
                        {error.billableHours}
                      </Typography>
                      {suggestionsbillable.length > 0 &&
                        (formData?.billableHours.includes("h") ||
                          formData?.billableHours.includes("m")) && (
                          <List
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginTop: "4px",
                            }}
                          >
                            {suggestionsbillable.map((option, index) => (
                              <ListItem
                                button
                                key={index}
                                onClick={() =>
                                  handleSuggestionClick(option, "billable")
                                }
                              >
                                {option}
                              </ListItem>
                            ))}
                          </List>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        select
                        label={
                          <span>
                            Status
                            {renderAsterisk()}
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name={projectId ? "statusValue" : "status"}
                        value={
                          projectId ? formData.statusValue : formData?.status
                        }
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {projectId
                          ? statusList?.map((option) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.status}
                              </MenuItem>
                            ))
                          : taskStatusList?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                      </TextField>
                      <Typography color="error">
                        {projectId ? error.statusValue : error.status}
                      </Typography>
                      {/* <TextField
                        fullWidth
                        select
                        label="Status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {taskStatusList?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.status}</Typography> */}
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        freeSolo
                        options={options?.map((option) => option?.title) || []}
                        value={null}
                        inputValue={inputValue}
                        onInputChange={(event, newValue) => {
                          setInputValue(newValue);
                        }}
                        onChange={(event, newValue) => {
                          if (
                            event.type !== "keydown" &&
                            newValue &&
                            !tags.some((t) => t.title === newValue)
                          ) {
                            handleAddTag(newValue);
                          }
                        }}
                        clearIcon={inputValue && <ClearIcon />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tags (Optional)"
                            variant="outlined"
                            placeholder="Press enter to add tags"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                handleAddTag(inputValue);
                              }
                            }}
                          />
                        )}
                      />
                      {error.tags && (
                        <Typography color="error">{error.tags}</Typography>
                      )}
                      <Box mt={2}>
                        {tags.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag.title}
                            onDelete={handleDeleteTag(tag)}
                            // deleteIcon={
                            //   <IconButton style={{ color: 'white', size:"10px" }}>
                            //     <CloseIcon />
                            //   </IconButton>
                            // }
                            style={{
                              margin: "4px",
                              backgroundColor: tag.color,
                              color: "#fff",
                              height: "25px",
                              fontSize: "12px",
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      {/* <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        value={formData.description}
                        multiline
                        rows={4}
                        onChange={handleChange}
                        variant="outlined"
                      /> */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "4px",
                          fontSize: "14.5px",
                          color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                        }}
                      >
                        <span>
                          Description
                          {renderAsterisk()}
                        </span>
                      </Box>
                      <ReactQuill
                        className={isDarkTheme ? "ReactQuillDark" : ""}
                        style={{
                          color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                          flex: 1,
                          // ".ql-snow .ql-fill": {
                          //   fill: "red !important",
                          // },
                          ".qlSnow .qlFill": {
                            fill: "red !important",
                          },
                        }}
                        theme="snow"
                        value={formData.description}
                        onChange={handleDescriptionChange}
                        // onChange={(content, delta, source, editor) => {
                        //   console.log('editor:', editor.getHTML());
                        //   setFormData((prevData) => ({
                        //     ...prevData,
                        //     "description": editor.getHTML(),
                        //   }));
                        //   setError((prevErrors) => ({
                        //     ...prevErrors,
                        //     "description": null,
                        //   }));
                        //   // dispatch(setNewTask(formData));
                        // }}
                        modules={modules}
                        formats={formats}
                        placeholder="Description"
                      />
                      <Typography color="error">{error.description}</Typography>
                    </Grid>
                    {/* Add more Grid items for other form fields */}
                  </Grid>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {taskAddingMessage && (
                        <Typography
                          sx={{
                            mr: 2,
                            color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                          }}
                        >
                          {taskAddingMessage}
                        </Typography>
                      )}
                      {displayMessageError && (
                        <Typography sx={{ mr: 2, color: "red" }}>
                          {displayMessageError}
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={setClose}
                        sx={{ ml: 2, marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Add Task
                      </Button>
                    </Box>
                  </Box>
                </form>
                <Box mt={2}>
                  <Typography sx={{ color: "red" }}>
                    {displayMessageError}
                  </Typography>
                </Box>
              </CardContent>
            </MainCard>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AddTaskForm;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material'

import EmpDataGrid from '../AdminDashboard/Tables/EmpDataGrid'
import {
    setAllData,
} from "../../Slices/UserDataSlice";
import { getTeamDetailData } from "../../Services/DashboardServices"
import { MainBox, InnerBoxOne, TitleTypography } from "../../Styles/Team/TeamStyle"

function Team() {
    const dispatch = useDispatch();
    const empData = useSelector((state) => state.employeeData.value);

    const getTeamDetails = async () => {
        try {
            const response = await getTeamDetailData();
            if (response.status === 200 || response.status === 201) {
                dispatch(setAllData(response.data.User));
            } else {
                dispatch(setAllData([]));
            }
        } catch (error) {
            dispatch(setAllData([]));
            console.log("error:", error);
        }
    };

    useEffect(() => {
        getTeamDetails();
        // eslint-disable-next-line
    }, [])

    return (
        <Box>
            <MainBox>
                <InnerBoxOne bottom={0}>
                    <TitleTypography variant="h6">
                        Team
                    </TitleTypography>
                </InnerBoxOne>
                <EmpDataGrid team={"Team"} Empdata={empData} selectedButton={"Active Employees"} />
            </MainBox>
        </Box>
    )
}

export default Team
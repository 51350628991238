import { Box, Button, styled, Grid } from "@mui/material";

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
}))

export const LeaveButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Leave"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Leave"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const AttendanceButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Attendance"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Attendance"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const TasksButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Tasks"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Tasks"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
        justifyContent: "flex-end",
      },
}))

export const EmployeeDetailBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "var(--secondary-text-color)"
}))

export const TaskEfficiencyButton = styled(Button)(() => ({
    marginRight: "4px",
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
}))

export const InnerGrid = styled(Grid)(() => ({
    '@media (max-width: 600px)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight:"3.5%",
        marginLeft:"0%"
      },
}))

import { Box, Card, TextField, styled } from "@mui/material"

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "96vh"
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "5px",
}))

export const SuggestionBox = styled(Box)(({ isdarktheme }) => ({
    borderRadius: "50%",
    backgroundColor: isdarktheme === "true" ? "#142840" : "#ffffff",
    display: "flex",
    padding: "0px 0px 0px 6px",
}))

export const CloseBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 5px 0px",
}))

export const MainCard = styled(Card)(() => ({
    boxShadow: 4,
    width: "100%",
    marginTop: "10px",
    background: "var(--background-table-sidebar-card-color)",
}))

export const AssignByTextField = styled(TextField)(() => ({
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
        borderColor: "var(--secondary-text-color)",
    },
    svg: {
        fill: "var(--secondary-text-color)",
    },
}))
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    useMediaQuery,
    Tooltip,
    Link,
    Zoom,
    Typography,
    Grid
} from "@mui/material";
import { Add, Edit, Delete, Close } from "@mui/icons-material";
import AssignmentIcon from '@mui/icons-material/Assignment';

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { TaskTypeCell } from "../ReusableComponents/UtilityFunctions";
import {
    getAllTaskTypes,
    upsertTaskType,
    deleteTaskType,
} from "../../Services/TaskManageServices";
import { errorMessage } from "../../Services/axiosInstance";
import {
    TitleTypography,
    CancelButton,
    AddButton,
    InnerBoxOneDialog,
    InnerBoxTwoDialog,
    ErrorTypography,
    CloseIconButton,
    DialogsActions,
    TitleBox,
    InnerBoxTwo,
    MainBoxAddDialog
} from "../../Styles/TaskManagement/TaskTypeStyle";
import { DeleteButton, MainBoxDialog } from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";

const TaskType = () => {
    const dispatch = useDispatch();
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    const isMobile = useMediaQuery("(max-width:600px)");

    const [taskTypes, setTaskTypes] = useState([]);
    const [newTaskType, setNewTaskType] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editId, setEditId] = useState(null);
    const [_IdForDelete, set_IdForDelete] = useState("");
    const [_dataThatDelete, set_DataThatDelete] = useState({});
    const [displayError, setDisplayError] = useState("");
    const [error, setError] = useState([]);

    const handleOpenAddDialog = () => {
        setIsDialogOpen(true);
    };

    const handleEditTaskType = (item) => {
        setEditId(item?._id)
        setNewTaskType(item?.taskTypeName)
        setIsDialogOpen(true);
    }

    const handleOpenDeleteDialog = (item) => {
        setDeleteDialogOpen(true);
        set_IdForDelete(item);
        set_DataThatDelete(item);
    }

    const onClose = () => {
        setEditId("")
        setNewTaskType("");
        setError([]);
        setIsDialogOpen(false);
    }

    const onClose2 = () => {
        set_IdForDelete("");
        set_DataThatDelete("")
        setDisplayError("");
        setDeleteDialogOpen(false);
    }

    const handleTaskTypeUpsert = async (row) => {
        const errors = {};
        if (!newTaskType) {
            errors.taskTypes = "Type is required!";
            setError(errors);
            return;
        }
        try {
            const postData = { taskTypeName: newTaskType };
            if (editId) {
                postData["id"] = editId;
            }
            const response = await upsertTaskType(JSON.stringify(postData))
            if (response.status === 200 || response.status === 201) {
                setNewTaskType("");
                onClose()
                setTaskTypes(prevData => {
                    const newTaskType = response?.data?.taskType;
                    if (editId) {
                        return prevData?.map(item =>
                            item?._id === newTaskType?._id ? newTaskType : item
                        );
                    } else {
                        return [...prevData, newTaskType];
                    }
                });
                dispatch(setToast(true));
                dispatch(setToastMsg(response.data.message ?? (editId ? "TaskType Updated." : "TaskType Added.")));
                dispatch(setToastType("success"));
            }
        }
        catch (error) {
            setDisplayError(errorMessage ?? "")
        }
    };

    const deletTaskTypeData = async (taskType) => {
        try {
            const response = await deleteTaskType(taskType?._id)
            if (response.status === 200 || response.status === 201) {
                const id = response?.data?.taskTypeId
                setTaskTypes(prevData => prevData?.filter(item => item?._id !== id));
                onClose2();
                dispatch(setToast(true));
                dispatch(setToastMsg(response.data.message ?? "taskType deleted."));
                dispatch(setToastType("success"));
            }
        }
        catch (error) {
            setDisplayError(errorMessage ?? "")
        }
    }

    const getAllTaskTypeData = async () => {
        try {
            const response = await getAllTaskTypes();
            if (response.status === 200) {
                setTaskTypes(response?.data?.taskTypes);
            } else {
                setTaskTypes([]);
            }
        } catch (error) {
            setTaskTypes([]);
        }
    }

    const renderDeleteDialog = () => {
        return (
            <>
                <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "8px" }}>
                    <MainBoxDialog>
                        <Box>
                            <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
                                <InnerBoxOneDialog>
                                    <Box sx={{ padding: "8px 5px", }}>
                                        <InnerBoxTwoDialog
                                            isdarktheme={isDarkTheme ? "true" : "false"}
                                        />
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                fontSize: { xs: "1rem", sm: "1.2rem" },
                                                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                                            }}
                                        >
                                            Confirm Delete TaskType
                                        </Box>
                                    </Box>
                                </InnerBoxOneDialog>
                            </DialogTitle>
                        </Box>
                        <Box>
                            <CloseIconButton
                                onClick={onClose2}
                                isdarktheme={isDarkTheme ? "true" : "false"}
                            >
                                <Close />
                            </CloseIconButton>
                        </Box>
                    </MainBoxDialog>
                    <DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
                        <Typography
                            sx={{
                                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                            }}
                        >
                            Are you sure you want to Delete{" "}
                            <span
                                style={{
                                    textDecoration: "underline",
                                }}
                            >
                                {_dataThatDelete?.taskTypeName}
                            </span>{" "}
                            taskType?
                        </Typography>
                    </DialogContent>
                    <DialogsActions displayError={displayError}>
                        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
                            <ErrorTypography>{displayError}</ErrorTypography>
                        </Box>
                        <Box>
                            <CancelButton
                                isdarktheme={isDarkTheme ? "true" : "false"}
                                onClick={onClose2}
                            >
                                Cancel
                            </CancelButton>
                            <DeleteButton
                                variant="contained"
                                onClick={() => {
                                    deletTaskTypeData(_IdForDelete);
                                }}
                                component={Link}
                                color="error"
                            >
                                Delete
                            </DeleteButton>
                        </Box>
                    </DialogsActions>
                </Box>
            </>)
    }

    const renderAddEditDialog = () => {
        return (
            <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "15px" }}>
                <MainBoxAddDialog>
                    <Box>
                        <DialogTitle sx={{ color: isDarkTheme ? "#e0e0e0e3;" : "#544f5a", padding: "0px 0px" }}>
                            <InnerBoxTwo>
                                <TitleBox>
                                    <AssignmentIcon
                                        color="primary"
                                        style={{ fontSize: "lg" }}
                                    />
                                </TitleBox>
                                <Box>
                                    {editId ? "Edit TaskType" : " Add TaskType"}
                                </Box>
                            </InnerBoxTwo>
                        </DialogTitle>
                    </Box>
                    <Box>
                        <CloseIconButton
                            ismobile={isMobile ? "true" : "false"}
                            onClick={onClose}
                            isdarktheme={isDarkTheme ? "true" : "false"}
                        >
                            <Close />
                        </CloseIconButton>
                    </Box>
                </MainBoxAddDialog>
                <DialogContent sx={{ padding: "0px" }}>
                    <Grid item xs={12}>
                        <TextField
                            label="Type"
                            name="newTaskType"
                            value={newTaskType}
                            onChange={(event) => {
                                setNewTaskType(event.target.value);
                                setDisplayError("");
                                setError([]);
                            }}
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: "5%" }}
                        />
                        <ErrorTypography>{error.taskTypes}</ErrorTypography>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: "10px 0px 0px 8px" }}>
                    <CancelButton
                        onClick={onClose}
                        color="secondary"
                    >
                        Cancel
                    </CancelButton>
                    <AddButton variant="contained" onClick={handleTaskTypeUpsert}>
                        {editId ? "Update" : "Save"}
                    </AddButton>
                </DialogActions>
            </Box >
        );
    };

    useEffect(() => {
        getAllTaskTypeData()
    }, []);

    return (<Box>
        {isMobile ? (
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <TitleTypography variant="h6" gutterBottom>
                        Manage TaskType
                    </TitleTypography>
                </Box>
                <Box style={{ alignSelf: "flex-end" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddDialog}
                        startIcon={<Add />}
                    >
                        Add TaskType
                    </Button>
                </Box>
            </Box>
        ) : (
            <>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <TitleTypography variant="h6" gutterBottom>
                        Manage TaskType
                    </TitleTypography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddDialog}
                        startIcon={<Add />}
                    >
                        Add TaskType
                    </Button>
                </Box>
            </>
        )}
        <List>
            {taskTypes?.map((item) => (
                <ListItem
                    key={item?._id}
                    divider
                    sx={{
                        borderBottom: "1px solid var(--table-border-color)",
                    }}
                >
                    <Box sx={{ marginRight: "7px" }}>
                        <TaskTypeCell
                            taskTypeName={item?.taskTypeName}
                        />
                    </Box>
                    <ListItemText
                        primary={item?.taskTypeName}
                        sx={{ color: "var(--secondary-text-color)", marginLeft: "7px" }}
                        secondaryTypographyProps={{
                            style: { color: "var(--secondary-text-color)" },
                        }}
                    />
                    <ListItemSecondaryAction>
                        <Tooltip
                            title="Edit"
                            placement="bottom"
                            arrow
                            TransitionComponent={Zoom}
                        >
                            <IconButton
                                edge="end"
                                color="primary"
                                onClick={() => handleEditTaskType(item)}
                                sx={{ color: "var( --secondary-text-color)" }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Delete"
                            placement="bottom"
                            arrow
                            TransitionComponent={Zoom}
                        >
                            <IconButton
                                edge="end"
                                color="secondary"
                                onClick={() => handleOpenDeleteDialog(item)}
                                sx={{
                                    color: isDarkTheme ? "#d94a38" : "#c62828",
                                    marginLeft: 2,
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>

        <DialogComponent
            open={isDialogOpen}
            onClose={onClose}
            maxWidth="280px"
            PaperProps={{
                sx: { width: "380px" }
            }}
            renderChildern={renderAddEditDialog}
        />
        <DialogComponent
            open={isDeleteDialogOpen}
            onClose={onClose2}
            maxWidth="md"
            renderChildern={renderDeleteDialog}
        />
    </Box>)
}
export default TaskType
import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import AdminLogin from "./components/Authentication/Login/Login";
import AdminSignup from "./components/Authentication/Registration/Signup";
import DashBoard from "./components/AdminDashboard/DashBoard";
import Leave from "./components/AdminDashboard/EmployeeButtons/Leave";
import CenterComponent from "./components/AdminDashboard/CenterComponent";
import AdminDetails from "./components/ReusableComponents/MenuItems/AdminDetails";
import AddEmpForm from "./components/ReusableComponents/AddEmpForm";
import ViewProfile from "./components/ViewProfile/ViewProfile";
import Profile from "./components/EmployeeManagement/Employee/Profile";
import EmpManagement from "./components/EmployeeManagement/EmpManagement";
import Attendance from "./components/EmployeeManagement/Employee/Attendence";
import LeaveManagement from "./components/LeaveManagement/LeaveManagement";
import ViewLeaveDetail from "./components/LeaveManagement/ViewLeaveDetail/ViewLeaveDetail";
import AttendanceManagement from "./components/AttendanceManagement/AttendanceManagement";
import UpdateAttendance from "./components/UpdateEmployee/UpdateAttendance/UpdateAttendance";
import TaskManagement from "./components/TaskManagement/TaskManagement";
import Project from "./components/ProjectManagement/Project";
import ManageRoles from "./components/ManageRoles/ManageRoles";
import KnowledgeSharing from "./components/KnowledgeSharing/KnowledgeSharing";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import NotFound from "./components/NotFound/NotFound";
import AddLeaveForm from "./components/DisplayEmployee/AddLeaveForm/AddLeaveForm";
import CompanyLeaveAndHolidayAccordian from "./components/CompanyAndLeavePolicy/CompanyLeaveAndHolidayAccordian";
import CompanyPolicyAccordian from "./components/CompanyAndLeavePolicy/CompanyPolicyAccordian";
import { ManageEvents } from "./components/ManageEvents/ManageEvents";
import ResetPassword from "./components/Authentication/ResetPassword/ResetPassword";
import SelectedEmployeeTaskDataGrid from "./components/AdminDashboard/Tables/SelectedEmployeeTaskDataGrid";
import { BirthdayPage } from "./components/BirthdayPage/BirthdayPage";
import { Authentication } from "./components/AdminDashboard/Authentication";
import { useInitializeTheme } from "./useInitializeTheme";
import { SettingPage } from "./components/Setting/SettingPage";
import RolePermission from "./components/RolePermission/RolePermission";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import TeamBoard from "./components/TeamBoard/TeamBoard";
import WorkFlow from "./components/WorkFlow/WorkFlow";
import Team from "./components/Team/Team";
import ConfigurationPage from "./components/Configuration/ConfigurationPage";
import TaskCategory from "./components/TaskManagement/TaskCategory";
import NotificationDetail from "./components/Notification/NotificationDetail";
import TaskDialogBox from "./components/TaskManagement/DetailTask";
import TaskType from "./components/TaskManagement/TaskType";
import TaskSprint from "./components/TaskManagement/TaskSprint";

import "./assets/styles/AccordianStyles.css";
import "./assets/styles/DatePickerUiStyles.css";
import "./assets/styles/EventCalendar.css";
import "react-quill/dist/quill.snow.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-calendar/dist/Calendar.css";
import "./App.css";

function App() {
  useInitializeTheme();

  const is_JT_theme_Dark =
    window.localStorage.getItem("is_JT_theme_Dark") === "true" ? true : false;

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Jost, sans-serif",
      },
    },
  });

  const ProtectedRoute = () => {
    const token = window.localStorage.getItem("Token");
    return token ? <Outlet key={Math.random()} /> : <Navigate to="/" />;
  };

  const PrivacyPolicyRoute = () => {
    // const location = useLocation();
    // const { from } = location.state || { from: "default" };

    if (!window.localStorage.getItem("Token")) {
      return (
        <Box
          sx={{
            padding: "12px",
            backgroundColor: is_JT_theme_Dark
              ? "var(--darkTheme-before-login-sideblock-bg) !important"
              : "var(--lightTheme-before-login-bg) !important",
            color: is_JT_theme_Dark
              ? "var(--darkTheme-inputfield-color) !important"
              : "var(--lightTheme-inputfield-color) !important",
          }}
        >
          <PrivacyPolicy />
        </Box>
      );
    } else {
      return (
        <DashBoard>
          <PrivacyPolicy />
        </DashBoard>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="App" sx={{ height: "100vh", overflow: "auto" }}>
        {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/signup" element={<AdminSignup />} />
          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route path="/birthday" element={<BirthdayPage />} />
          <Route path="/authentication" element={<Authentication />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyRoute />} />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/dashboard"
              element={
                <DashBoard>
                  <RolePermission
                    component={<CenterComponent />}
                    pageName={"Dashboard"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/teamboard"
              element={
                <DashBoard>
                  <RolePermission
                    component={<TeamBoard />}
                    pageName={"TeamBoard"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/notificationDetails"
              element={
                <DashBoard>
                  <NotificationDetail />
                </DashBoard>
              }
            />
            <Route
              path="/notificationDetails"
              element={
                <DashBoard>
                  <NotificationDetail />
                </DashBoard>
              }
            />
            <Route
              path="/profile"
              element={
                <DashBoard>
                  <RolePermission
                    component={<Profile />}
                    pageName={"My Profile"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/profile/:employee_id"
              element={
                <DashBoard>
                  <ViewProfile />
                </DashBoard>
              }
            />
            <Route
              path="/employee-management"
              element={
                <DashBoard>
                  <RolePermission
                    component={<EmpManagement />}
                    pageName={"Employee Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/employee-management/:employeeId"
              element={
                <DashBoard>
                  {/* <ViewProfile /> */}
                  <RolePermission
                    component={<ViewProfile />}
                    pageName={"Employee Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/employee/add"
              element={
                <DashBoard>
                  <RolePermission
                    component={<AddEmpForm />}
                    pageName={"Add Employee"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/leave-management"
              element={
                <DashBoard>
                  <RolePermission
                    component={<LeaveManagement />}
                    pageName={"Leave Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/leave-details"
              element={
                <DashBoard>
                  <ViewLeaveDetail />
                </DashBoard>
              }
            />
            <Route
              path="/leaves"
              element={
                <DashBoard>
                  <RolePermission component={<Leave />} pageName={"Leaves"} />
                </DashBoard>
              }
            />
            <Route
              path="/leave/add"
              element={
                <DashBoard>
                  <RolePermission
                    component={<AddLeaveForm />}
                    pageName={"Add Leave"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/attendance-management"
              element={
                <DashBoard>
                  <RolePermission
                    component={<AttendanceManagement />}
                    pageName={"Attendance Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/attendance-management/:employeeId"
              element={
                <DashBoard>
                  <UpdateAttendance />
                </DashBoard>
              }
            />
            <Route
              path="/attendance"
              element={
                <DashBoard>
                  <RolePermission
                    component={<Attendance />}
                    pageName={"Attendance"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/task-management"
              element={
                <DashBoard>
                  <RolePermission
                    component={<TaskManagement />}
                    pageName={"Task Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/tasks"
              element={
                <DashBoard>
                  <RolePermission
                    component={<TaskManagement />}
                    pageName={"Tasks"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/task-management/:employeeId"
              element={
                <DashBoard>
                  {/* <SelectedEmployeeTask /> */}
                  {/* <SelectedEmployeeTaskDataGrid /> */}
                  <RolePermission
                    component={<SelectedEmployeeTaskDataGrid />}
                    pageName={"Task Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/project-management"
              element={
                <DashBoard>
                  <RolePermission
                    component={<Project />}
                    pageName={"Project Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/projects"
              element={
                <DashBoard>
                  <RolePermission
                    component={<Project />}
                    pageName={"Projects"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/manage-roles"
              element={
                <DashBoard>
                  <RolePermission
                    component={<ManageRoles />}
                    pageName={"Role Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/manage-events"
              element={
                <DashBoard>
                  <RolePermission
                    component={<ManageEvents />}
                    pageName={"Event Management"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/events"
              element={
                <DashBoard>
                  <RolePermission
                    component={<ManageEvents />}
                    pageName={"Events"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/general-policy"
              element={
                <DashBoard>
                  <RolePermission
                    component={<CompanyPolicyAccordian />}
                    pageName={"General Policy"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/leave-policy"
              element={
                <DashBoard>
                  <RolePermission
                    component={<CompanyLeaveAndHolidayAccordian />}
                    pageName={"Leave Policy"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/knowledge-sharing"
              element={
                <DashBoard>
                  <RolePermission
                    component={<KnowledgeSharing />}
                    pageName={"Knowledge Sharing"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/workflow"
              element={
                <DashBoard>
                  <RolePermission
                    component={<WorkFlow />}
                    pageName={"WorkFlow"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/team"
              element={
                <DashBoard>
                  <RolePermission component={<Team />} pageName={"Team"} />
                </DashBoard>
              }
            />
            {/* Change route and file name*/}
            <Route
              path="/my-profile"
              element={
                <DashBoard>
                  <AdminDetails />
                </DashBoard>
              }
            />
            {/* Change route and file name*/}
            <Route
              path="/setting"
              element={
                <DashBoard>
                  <RolePermission
                    component={<SettingPage />}
                    pageName={"Setting"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/configuration"
              element={
                <DashBoard>
                  <RolePermission
                    component={<ConfigurationPage />}
                    pageName={"Configuration"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/task-category"
              element={
                <DashBoard>
                  <RolePermission
                    component={<TaskCategory />}
                    pageName={"Category"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/task-type"
              element={
                <DashBoard>
                  <RolePermission
                    component={<TaskType />}
                    pageName={"TaskType"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/task-management/:ticketNo"
              element={<TaskDialogBox />}
            />
            <Route
              path="/task-sprint"
              element={
                <DashBoard>
                  <RolePermission
                    component={<TaskSprint />}
                    pageName={"Sprint"}
                  />
                </DashBoard>
              }
            />
            <Route
              path="/access-denied"
              element={
                <DashBoard>
                  <AccessDenied />
                </DashBoard>
              }
            />
            {/* <Route
                path="/privacy-policy"
                element={
                  <DashBoard>
                    <PrivacyPolicy />
                  </DashBoard>
                }
              /> */}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* </BrowserRouter> */}
      </Box>
    </ThemeProvider>
  );
}

export default App;
